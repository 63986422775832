import React, { useState, useContext, useEffect, useRef  } from 'react';
import {
  Card,
  Divider,
  CardHeader
} from '@material-ui/core';
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl } from '@material-ui/core';
import { getFormatDate } from '../../../common/getFormatDate';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { checkPgGrid } from '../../../common/grid/checkPgGrid';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { MyContext } from '../../../App';
import { dateRange } from '../../../common/dateRange';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router-dom';
import { dateYYYYMMDDGrid } from '../../../common/grid/dateYYYYMMDDGrid';
import { dateTimeGrid } from '../../../common/grid/dateTimeGrid';
import { numberWithCommasGrid } from '../../../common/grid/numberWithCommasGrid';
import { quotaMoanGrid } from '../../../common/grid/quotaMonGrid';
import { transStatusGrid } from '../../../common/grid/transStatusGrid';
import { transExcel } from '../../../common/excel/transExcel';
import { getCurrentDateYYYYMMDD } from '../../../common/getCurrentDateYYYYMMDD';
import ko from 'date-fns/locale/ko';

const Cancel = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  //선택하세요 
  const [selectTypeList, setSelectTypeList] = useState([{}]);
  //선택하세요 값 Value
  const [selectType, setSelectType] = useState('select');

  const { selectInfoList, setSelectInfoList } = useContext(MyContext);

  const currentDate = new Date();
  //시작일자 ,종료일자
  const [startDate, setStartDate] = React.useState(getFormatDate(currentDate));
  const [endDate, setEndDate] = React.useState(getFormatDate(currentDate));
    const [isReadyForFinish, setIsReadyForFinish] = useState(false);
  //거래일자 ,취소일자
  const dateList = [{ value: 'appdt', lavel: '거래일자' }, { value: 'ccdt', lavel: '취소일자' }, {value: 'regdt', lavel: '요청 접수일자'}];
  const [dateType, setDateType] = useState('regdt');

  //권한 show hide
  const [l1vidDisplay, setl1vidDisplay] = React.useState(false);
  const [l2vidDisplay, setl2vidDisplay] = React.useState(false);
  const [l3vidDisplay, setl3vidDisplay] = React.useState(false);

  const today = getCurrentDateYYYYMMDD();

    //1차PG사
  const selectPgList = (
    [{ value: 'all', lavel: '모두' }
      , { value: '01', lavel: '코페이' }
      , { value: '02', lavel: '올앳' }
      , { value: '03', lavel: '다날' }
      , { value: '04', lavel: '갤컴' }
      , { value: '05', lavel: 'KIS' }
      , { value: '06', lavel: '토스' }
      , { value: '07', lavel: 'KSNET' }
      , { value: '08', lavel: '섹타나인' }
    ]);
  const [selectPgType, setSelectPgType] = useState('all');

    //권한에 따라 show hide 설정
    function styleDisplay(usrType) {
        if (usrType == 4) {
            setl1vidDisplay(true);
            setSelectTypeList(
                [{ value: 'select', lavel: '선택하세요' }
                    , { value: 'cono', lavel: '사업자번호' }
                    , { value: 'l2vid', lavel: '대리점' }
                    , { value: 'l3vid', lavel: '딜러' }
                    , { value: 'l2vidNm', lavel: '대리점명' }
                    , { value: 'l3vidNm', lavel: '딜러명' }
                    , { value: 'mid', lavel: 'MID' }
                    , { value: 'gid', lavel: 'GID' }
                    , { value: 'catId',lavel :'단말기번호'}
                    , { value: 'ordNm', lavel : '구매자'}
                ]);
        } else if (usrType == 3) {
            setl1vidDisplay(true);
            setl2vidDisplay(true);
            setSelectTypeList(
                [{ value: 'select', lavel: '선택하세요' }
                    , { value: 'cono', lavel: '사업자번호' }
                    , { value: 'l3vid', lavel: '딜러' }
                    , { value: 'l3vidNm', lavel: '딜러명' }
                    , { value: 'mid', lavel: 'MID' }
                    , { value: 'gid', lavel: 'GID' }
                    , { value: 'catId',lavel :'단말기번호'}
                    , { value: 'ordNm', lavel : '구매자'}
                ]);
        } else if (usrType == 1) {
            setl1vidDisplay(true);
            setl2vidDisplay(true);
            setl3vidDisplay(true);
            setSelectTypeList(
                [{ value: 'select', lavel: '선택하세요' }
                    , { value: 'cono', lavel: '사업자번호' }
                    , { value: 'mid', lavel: 'MID' }
                    , { value: 'gid', lavel: 'GID' }
                    , { value: 'catId',lavel :'단말기번호'}
                    , { value: 'ordNm', lavel : '구매자'}
                ]);
        } else {
            setSelectTypeList([{ value: 'select', lavel: '선택하세요' }
                , { value: 'cono', lavel: '사업자번호' }
                , { value: 'vid', lavel: '총판' }
                , { value: 'l2vid', lavel: '대리점' }
                , { value: 'l3vid', lavel: '딜러' }
                , { value: 'vidNm', lavel: '총판명' }
                , { value: 'l2vidNm', lavel: '대리점명' }
                , { value: 'l3vidNm', lavel: '딜러명' }
                , { value: 'mid', lavel: 'MID' }
                , { value: 'gid', lavel: 'GID' }
                , { value: 'catId',lavel :'단말기번호'}
                , { value: 'ordNm', lavel : '구매자'}
            ]);
        }
    }

  //매입카드사
  const selectCardList = (
    [{ value: 'all', lavel: '모두' }
      , { value: '01', lavel: '비씨' }
      , { value: '02', lavel: '국민' }
      , { value: '03', lavel: '하나' }
      , { value: '04', lavel: '삼성' }
      , { value: '06', lavel: '신한' }
      , { value: '07', lavel: '현대' }
      , { value: '08', lavel: '롯데' }
      , { value: '12', lavel: '농협' }
      , { value: '15', lavel: '우리' }
    ]);
  const [selectCardType, setSelectCardType] = useState('all');


  //거래상태
  const selectTrxStCdList = (
    [{ value: 'all', lavel: '모두' }
      , { value: '1', lavel: '요청' }
      , { value: '2', lavel: '완료' }
      , { value: '3', lavel: '반려' }
      , { value: '4', lavel: '확인중' }
      , { value: '3', lavel: '입금완료' }
    ]);
  const [selectTrxStCdType, setSelectTrxStCdType] = useState('all');

    const gridRef = useRef(null); // 그리드 API에 접근하기 위한 참조 생성
    const [selectedRows, setSelectedRows] = useState([]);

    const onGridReady = (params) => {
        gridRef.current = params; // API를 gridRef.current에 저장
    };

    const onSelectionChanged = () => {
        if (gridRef.current) {
            const selectedData = gridRef.current.api.getSelectedRows(); // api 객체 통해 getSelectedRows 접근
            setSelectedRows(selectedData); // 선택된 행을 상태로 저장
            console.log("Selected Rows:", selectedData); // 콘솔에 선택된 데이터 출력

            const allReadyForFinish = selectedData.every(row => row.CANCEL_STATUS === '입금대기');
            setIsReadyForFinish(allReadyForFinish); // 상태 업데이트
        }
    }


  //날짜 버튼
  const dateButton = (e) => {
    const date = new Date();
    const dates = new Date();
    if (e.target.value == '0') {
      date.setDate(date.getDate() - 1);
      setStartDate(getFormatDate(date));
      setEndDate(getFormatDate(date));
    } else if (e.target.value == '1') {
      date.setDate(date.getDate());
      setStartDate(getFormatDate(date));
      setEndDate(getFormatDate(date));
    } else if (e.target.value == '2') {
      date.setDate(date.getDate() - 7);
      dates.setDate(dates.getDate());
      setStartDate(getFormatDate(date));
      setEndDate(getFormatDate(dates));
    } else if (e.target.value == '3') {
      date.setDate(date.getDate() - 31);
      dates.setDate(dates.getDate());
      setStartDate(getFormatDate(date));
      setEndDate(getFormatDate(dates));
    }
  }

    const [columnDefs, setColumnDefs] = useState([
        { headerCheckboxSelection: true, checkboxSelection: true, width: 50 },
        { headerName: "순번", field: "REQ_NO", width: 80 },
        { headerName: "승인일자", field: "APP_DT", cellRenderer: dateYYYYMMDDGrid, width: 120 },
        { headerName: "취소일자", field: "CC_DT", cellRenderer: dateYYYYMMDDGrid, width: 120 },
        { headerName: "TID", field: "TID",width: 300 },
        { headerName: "1차PG", field: "VAN_CD",width: 120 },
        { headerName: "정산유형", field: "STMT_CYCLE_CD",width: 100 },
        { headerName: "MID", field: "MID", width: 120 },
        { headerName: "사업자번호", field: "CO_NO", width: 120 },
        { headerName: "상호", field: "CO_NM", width: 200 },
        { headerName: "승인번호", field: "APP_NO", width: 100 },
        { headerName: "취소유형", field: "CANCEL_TYPE", width: 100 },
        { headerName: "취소사유", field: "CANCEL_MNG", width: 200 },
        { headerName: "입금요청금액", field: "CANCEL_REQUEST_AMT", cellRenderer: numberWithCommasGrid, width: 120 },
        { headerName: "취소요청금액", field: "CC_AMT", cellRenderer: numberWithCommasGrid, width: 120 },
        {
            headerName: "진행상태", field: "CANCEL_STATUS", width: 120,
            cellStyle: (params) => {
                console.log("CANCEL_STATUS:", params.value); // 디버깅용 로그
                switch (params.value) {
                    case '취소완료': // 취소완료
                        return { color: 'black' };
                    case '반려': // 반려
                        return { color: 'black' };
                    case '입금확인중': // 입금확인중
                        return {  color: 'purple' };
                    case '입금완료': // 입금완료
                        return { color: 'orange' };
                    case '취소요청': // 입금완료
                        return { color: 'blue' };
                    case '입금대기': // 입금완료
                        return { color: 'green' };
                    default:
                        return {}; // 기본 스타일
                }
            }
        },
        { headerName: "입금은행", field: "BANK_CD", width: 100 },
        { headerName: "입금계좌", field: "ACNT_NO", width: 150 },
    ]);

    const [columnState, setColumnState] = useState(null);

    useEffect(() => {
        // 컴포넌트 마운트 시 컬럼 상태 로드
        const savedState = JSON.parse(localStorage.getItem("columnState"));
        if (savedState) {
            setColumnState(savedState);
        }
    }, []);

    // 컬럼 상태가 변경될 때 로컬 스토리지에 저장
    useEffect(() => {
        if (columnState) {
            localStorage.setItem("columnState", JSON.stringify(columnState));
        }
    }, [columnState]);

    useEffect(() => {
        styleDisplay(cookies.type);
    }, []);

    //그리드 사이즈
  const rowHeight = 40;
  //거래 리스트 data
  const [rowData, setRowData] = useState([]);

  //거래 리스트 total
  const [rowDataTotal, setRowDataTotal] = useState({});

  //취소내역리스트 조회
  const selectCancelList = () => {

    let limitDay = dateRange(startDate, endDate);
    if (limitDay > 100) {
      swal({
        text: '시작일자와(과) 종료일자의 간격은 100일 이내여야 합니다.',
        button: {
          text: '확인',
        },
      });
      return false;
    }
    Swal.showLoading();
    axios.
      post(process.env.REACT_APP_API_HOST + "/mms/selectCancelList"
        , {
          token: cookies.token,
          userId : cookies.userid,
          usrTypeId: cookies.id,
          usrType: cookies.type,
          l1_vid: selectInfoList.l1Vid,
          l2_vid: selectInfoList.l2Vid,
          l3_vid: selectInfoList.l3Vid,
          mid: selectInfoList.mid,
          startDate: deleteSpecialChar(startDate),
          endDate: deleteSpecialChar(endDate),
          dateType: dateType,
          selectPgType: selectPgType,
          selectCardType: selectCardType,
          selectTrxStCdType: selectTrxStCdType,
          selectType: selectType,
/*          textSelectType: document.getElementById('textSelectType').value,*/
          textTid: document.getElementById('textTid').value,
          textAppNo: document.getElementById('textAppNo').value,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      ).then(function (response) {
        if (response.data.res_code != '0000') {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          });
          if (response.data.res_code == '1004') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            navigate('/login', { replace: false });
          }
          Swal.close();
        } else {
          setRowData(response.data.data);
          setRowDataTotal(response.data.total);
          Swal.close();
        }
      }).catch(function (error) {
        Swal.close();
        swal({
          text: '미지정 오류',
          button: {
            text: '확인',
          },
        });
      });
  }
  //취소완료 버튼
    const cancelUpdate = () => {

        const selectedTIDs = selectedRows.map(row => ({ TID: row.TID, REQ_NO: row.REQ_NO }));
        axios.
        post(process.env.REACT_APP_API_HOST + "/mms/cancelUpdate"
            , {
                selectedTIDs: selectedTIDs,
                token: cookies.token,
                userId : cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }
        ).then(function (response) {
            if (response.data.res_code != '0000') {
                swal({
                    text: response.data.res_msg,
                    button: {
                        text: '확인',
                    },
                });
                if (response.data.res_code == '1004') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    navigate('/login', { replace: false });
                }
                Swal.close();
            } else {
                setRowData(response.data.data);
                /*setRowDataTotal(response.data.total);*/
                Swal.close();
                selectCancelList();
            }
        }).catch(function (error) {
            Swal.close();
            swal({
                text: '미지정 오류',
                button: {
                    text: '확인',
                },
            });
        });
    }

    //입금대기 버튼
    const cancelMoneyUpdate = () => {
        swal({
            text: "입금확인 요청하시겠습니까?",
            buttons: {
                confirm: {
                    text: "예",
                    value: true,
                    visible: true,
                    className: "btn-confirm",
                    closeModal: true,
                },
                cancel: {
                    text: "아니오",
                    value: false,
                    visible: true,
                    className: "btn-cancel",
                    closeModal: true,
                },
            },
        }).then((willProceed) => {
            if (willProceed) {
                // 예를 선택한 경우 API 요청 진행
                const selectedTIDs = selectedRows.map(row => ({ TID: row.TID, REQ_NO: row.REQ_NO }));
                axios
                    .post(
                        process.env.REACT_APP_API_HOST + "/mms/cancelMoneyUpdate",
                        {
                            selectedTIDs: selectedTIDs,
                            token: cookies.token,
                            userId: cookies.userid,
                            usrTypeId: cookies.id,
                            usrType: cookies.type,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-Requested-With": "XMLHttpRequest",
                            },
                        }
                    )
                    .then(function (response) {
                        if (response.data.res_code !== "0000") {
                            swal({
                                text: response.data.res_msg,
                                button: { text: "확인" },
                            });
                            if (response.data.res_code === "1004") {
                                swal({
                                    text: response.data.res_msg,
                                    button: { text: "확인" },
                                });
                                navigate("/login", { replace: false });
                            }
                        } else {
                            // 두 번째 알림 표시
                            swal({
                                text: "입금확인을 요청했습니다. 입금이 확인되면 [입금완료] 상태로 변경됩니다.",
                                button: { text: "확인" },
                            }).then(() => {
                                setRowData(response.data.data);
                                selectCancelList();
                            });
                        }
                    })
                    .catch(function (error) {
                        swal({
                            text: "미지정 오류",
                            button: { text: "확인" },
                        });
                    });
            }
        });
    };

    //입금완료 버튼
    const cancelMoneyFinish = () => {
        if(selectedRows.length === 0 ) {
            swal("선택된 행이 없습니다.");
            return;
        }

        if (!isReadyForFinish) {
            swal("선택된 항목이 '입금대기' 상태여야 합니다.");
            return;
        }
        swal({
            text: "입금완료 하시겠습니까?",
            buttons: {
                confirm: {
                    text: "예",
                    value: true,
                    visible: true,
                    className: "btn-confirm",
                    closeModal: true,
                },
                cancel: {
                    text: "아니오",
                    value: false,
                    visible: true,
                    className: "btn-cancel",
                    closeModal: true,
                },
            },
        }).then((willProceed) => {
            if (willProceed) {
                // 예를 선택한 경우 API 요청 진행
                const selectedTIDs = selectedRows.map(row => ({ TID: row.TID, REQ_NO: row.REQ_NO }));
                axios
                    .post(
                        process.env.REACT_APP_API_HOST + "/mms/cancelMoneyFinish",
                        {
                            selectedTIDs: selectedTIDs,
                            token: cookies.token,
                            userId: cookies.userid,
                            usrTypeId: cookies.id,
                            usrType: cookies.type,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-Requested-With": "XMLHttpRequest",
                            },
                        }
                    )
                    .then(function (response) {
                        if (response.data.res_code !== "0000") {
                            swal({
                                text: response.data.res_msg,
                                button: { text: "확인" },
                            });
                            if (response.data.res_code === "1004") {
                                swal({
                                    text: response.data.res_msg,
                                    button: { text: "확인" },
                                });
                                navigate("/login", { replace: false });
                            }
                        } else {
                            setRowData(response.data.data);
                            /*setRowDataTotal(response.data.total);*/
                            Swal.close();
                            selectCancelList();
                        }
                    })
                    .catch(function (error) {
                        swal({
                            text: "미지정 오류",
                            button: { text: "확인" },
                        });
                    });
            }
        });
    };

    //반려버튼
    const cancelReturn = () => {
        swal({
            text: "반려 하시겠습니까?",
            buttons: {
                confirm: {
                    text: "예",
                    value: true,
                    visible: true,
                    className: "btn-confirm",
                    closeModal: true,
                },
                cancel: {
                    text: "아니오",
                    value: false,
                    visible: true,
                    className: "btn-cancel",
                    closeModal: true,
                },
            },
        }).then((willProceed) => {
            if (willProceed) {
                // 예를 선택한 경우 API 요청 진행
                const selectedTIDs = selectedRows.map(row => ({ TID: row.TID, REQ_NO: row.REQ_NO }));
                axios
                    .post(
                        process.env.REACT_APP_API_HOST + "/mms/cancelReturn",
                        {
                            selectedTIDs: selectedTIDs,
                            token: cookies.token,
                            userId: cookies.userid,
                            usrTypeId: cookies.id,
                            usrType: cookies.type,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-Requested-With": "XMLHttpRequest",
                            },
                        }
                    )
                    .then(function (response) {
                        if (response.data.res_code !== "0000") {
                            swal({
                                text: response.data.res_msg,
                                button: { text: "확인" },
                            });
                            if (response.data.res_code === "1004") {
                                swal({
                                    text: response.data.res_msg,
                                    button: { text: "확인" },
                                });
                                navigate("/login", { replace: false });
                            }
                        } else {
                            setRowData(response.data.data);
                            /*setRowDataTotal(response.data.total);*/
                            Swal.close();
                            selectCancelList();
                        }
                    })
                    .catch(function (error) {
                        swal({
                            text: "미지정 오류",
                            button: { text: "확인" },
                        });
                    });
            }
        });
    };

  return (
    <>
      <div style={{ float: 'right' }}>
        <Button size="small" variant="contained" onClick={selectCancelList} color="primary">조회</Button>
      </div>
      <br></br>
      <br></br>
      <GroupVidVidGidMidInfo />
      <br></br>
      <Card>
        <FormControl sx={{ m: 1, width: 250 }}>
          <Select size='small'
            //  maxDate={currentDate}
            defaultValue={'regdt'}
            value={dateList.value}
            inputProps={{
              id: 'selectDateType',
            }}
            onChange={(event) => {
              setDateType(event.target.value);
            }}
            style={{ marginRight: '15px' }}
          >
            {dateList?.map(option => {
              return (
                <MenuItem key={option} value={option.value}>{option.lavel}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
          <DatePicker
            value={startDate}
            inputFormat={"yyyy/MM/dd"}
            mask={"____/__/__"}
            onChange={(newValue) => {
              setStartDate(getFormatDate(newValue));
            }}
            renderInput={(params) => <TextField style={{ width: '150px' }} size='small'{...params} />}
          />
        </LocalizationProvider>
        ~
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
          <DatePicker
            //  maxDate={currentDate}
            value={endDate}
            inputFormat={"yyyy/MM/dd"}
            mask={"____/__/__"}
            onChange={(newValue) => {
              setEndDate(getFormatDate(newValue));
            }}
            renderInput={(params) => <TextField
              style={{ width: '150px' }} size='small'{...params} />}
          />
        </LocalizationProvider>

        <Button size="medium" style={{ marginLeft: '10px' }} value="0" onClick={dateButton} variant="outlined" color="primary">어제</Button>
        <Button size="medium" style={{ marginLeft: '10px' }} value="1" onClick={dateButton} variant="outlined" color="primary">오늘</Button>
        <Button size="medium" style={{ marginLeft: '10px' }} value="2" onClick={dateButton} variant="outlined" color="primary">일주일</Button>
        <Button size="medium" style={{ marginLeft: '10px' }} value="3" onClick={dateButton} variant="outlined" color="primary">1개월</Button>
      </Card>
      <br></br>
      <Card>
        <Table size="small" aria-label="a dense table">
          <TableRow>
            <TableCell>
              TID
            </TableCell>
            <TableCell>
              <TextField id="textTid" style={{ minWidth: '250px' }} variant="outlined" size="small" />
            </TableCell>
            <TableCell>
              승인번호
            </TableCell>
            <TableCell>
              <TextField id="textAppNo" style={{ minWidth: '250px' }} variant="outlined" size="small" />
            </TableCell>
              <TableCell>
                  진행상태
              </TableCell>
              <TableCell>
                  <FormControl>
                      <Select
                          size='small'
                          value={selectTrxStCdList.value}
                          defaultValue={selectTrxStCdType}
                          onChange={(event) => {
                              setSelectTrxStCdType(event.target.value);
                          }}
                          style={{ minWidth: '120px' }}
                      >
                          {selectTrxStCdList?.map((option ,idx) => {
                              return (
                                  <MenuItem key={idx} value={option.value}>{option.lavel}</MenuItem>
                              );
                          })}
                      </Select>
                  </FormControl>
              </TableCell>
          </TableRow>
        </Table>
      </Card>
      <br></br>
      <br></br>
      {<Card>
        <Divider />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
                        <div style={{ fontSize: '26px', fontWeight: 'bold', color: 'blue' }}>{numberWithCommas(rowDataTotal?.REQUEST_TOTAL || 0)}</div>
                        <div style={{ fontSize: '15px', marginTop: '20px', fontWeight: 'bold' }}>요청</div>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
                        <div style={{ fontSize: '26px', fontWeight: 'bold', color: 'green' }}>{numberWithCommas(rowDataTotal?.MONEY_WAIT || 0)}</div>
                        <div style={{ fontSize: '15px', marginTop: '20px', fontWeight: 'bold' }}>입금대기</div>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
                        <div style={{ fontSize: '26px', fontWeight: 'bold', color: 'purple' }}>{numberWithCommas(rowDataTotal?.MONEY_CHECK_TOTAL || 0)}</div>
                        <div style={{ fontSize: '15px', marginTop: '20px', fontWeight: 'bold'}}>입금확인중</div>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
                        <div style={{ fontSize: '26px', fontWeight: 'bold', color: 'orange' }}>{numberWithCommas(rowDataTotal?.MONEY_FINISH_TOTAL || 0)}</div>
                        <div style={{ fontSize: '15px', marginTop: '20px', fontWeight: 'bold' }}>입금완료</div>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
                        <div style={{ fontSize: '26px', fontWeight: 'bold', color: 'black' }}>{numberWithCommas(rowDataTotal?.CANCEL_FINISH_TOTAL || 0)}</div>
                        <div style={{ fontSize: '15px', marginTop: '20px', fontWeight: 'bold' }}>취소완료</div>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
                        <div style={{ fontSize: '30px', fontWeight: 'bold', color: 'black' }}>{numberWithCommas(rowDataTotal?.CANCEL_RETURN_TOTAL || 0)}</div>
                        <div style={{ fontSize: '15px', marginTop: '20px', fontWeight: 'bold' }}>반려</div>
                    </TableCell>
                </TableRow>
            </TableHead>
        </Table>
      </Card>}
      <br></br>
      <br></br>
      <Card>
        <CardHeader/>
          <div style={{marginBottom: '10px', textAlign: 'right'}}>
             {/* <Button onClick={cancelMoneyUpdate} size="small" variant="contained"
                      style={{marginRight: '10px', backgroundColor: 'green', color: 'white' }}>
                  입금대기
              </Button>*/}
              <Button onClick={cancelMoneyFinish}  size="small" variant="contained"
                      style={{marginRight: '10px', backgroundColor: 'purple', color: 'white' }} >
                  입금완료
              </Button>
           {/*   <Button onClick={cancelUpdate} size="small" variant="contained" color="primary"
                      style={{marginRight: '10px'}}>
                  취소완료
              </Button>*/}
{/*              <Button onClick={cancelReturn} size="small" variant="contained" style={{ backgroundColor: 'orange', color: 'white' }}>
                  반려
              </Button>*/}
          </div>
          <Divider/>
          <div className="ag-theme-alpine" style={{height: 520, width: '100%'}}>
              <AgGridReact rowHeight={rowHeight}
                           defaultColDef={{resizable: true}}
                           debounceVerticalScrollbar={true}
                           enableCellTextSelection={true}
                           pagination={true}
                           paginationPageSize={10}
                           columnDefs={columnDefs}
                           ref={gridRef}
                           onGridReady={onGridReady}
                           onSelectionChanged={onSelectionChanged}
                           onColumnMoved={event => {
                               const updatedColumnState = event.columnApi.getColumnState();
                   setColumnState(updatedColumnState);
                   localStorage.setItem("columnState", JSON.stringify(updatedColumnState));
               }}
               onGridReady={event => {
                   const savedState = JSON.parse(localStorage.getItem("columnState"));
                   if (savedState) {
                       event.columnApi.applyColumnState({
                           state: savedState,
                           applyOrder: true,
                       });
                   }
               }}
            rowData={rowData}>
            <AgGridColumn headerCheckboxSelection checkboxSelection width={50} headerName="선택" />
            <AgGridColumn width={80} headerName="순번" field="REQ_NO" ></AgGridColumn>
            <AgGridColumn width={100} headerName="승인일자" field="APP_TM" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
            <AgGridColumn width={120} headerName="취소일자" field="CC_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
            <AgGridColumn width={120} headerName="TID" field="TID"></AgGridColumn>
            <AgGridColumn width={120} headerName="1차PG" field="VAN_CD"></AgGridColumn>
            <AgGridColumn width={100} headerName="정산유형" field="STMT_CYCLE_CD"></AgGridColumn>
            <AgGridColumn width={120} headerName="MID" field="MID"></AgGridColumn>
            <AgGridColumn width={120} headerName="사업자번호" field="CO_NO"></AgGridColumn>
            <AgGridColumn width={200} headerName="상호" field="CO_NM"></AgGridColumn>
            <AgGridColumn width={100} headerName="승인번호" field="APP_NO"></AgGridColumn>
            <AgGridColumn width={100} headerName="취소유형" field="CANCEL_TYPE"></AgGridColumn>
            <AgGridColumn width={200} headerName="취소사유" field="CANCEL_MNG"></AgGridColumn>
            <AgGridColumn width={100} headerName="입금금액" field="CANCEL_REQUEST_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
            <AgGridColumn width={100} headerName="취소금액" field="CC_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
            <AgGridColumn width={120} headerName="진행상태" field="CANCEL_STATUS"></AgGridColumn>
            <AgGridColumn width={150} headerName="입금은행" field="BANK_CD"></AgGridColumn>
            <AgGridColumn width={150} headerName="입금계좌" field="ACNT_NO"></AgGridColumn>
          </AgGridReact>
        </div>
      </Card>
    </>
  );
};


export default Cancel;