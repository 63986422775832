import React, { useState, useContext, useEffect } from 'react';
import { Card, CardHeader, FormControl } from '@material-ui/core';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo';
import Button from '@mui/material/Button';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { MyContext } from '../../../App';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ko from 'date-fns/locale/ko';
import { getFormatDate } from '../../../common/getFormatDate';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import { numberWithCommasGrid } from '../../../common/grid/numberWithCommasGrid';
import { dateYYYYMMDDGrid } from '../../../common/grid/dateYYYYMMDDGrid';
import { dateTimeGrid } from '../../../common/grid/dateTimeGrid';
import { dateRange } from '../../../common/dateRange';

const NotiResend = () => {
    const { selectInfoList } = useContext(MyContext);
    const [cookies] = useCookies();
    const [rowData, setRowData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]); // 선택된 행 저장
    const [columnDefs, setColumnDefs] = useState([]); // 컬럼 정의
    const navigate = useNavigate();

    const currentDate = new Date();
    const [startDate, setStartDate] = useState(getFormatDate(currentDate));
    const [endDate, setEndDate] = useState(getFormatDate(currentDate));
    const dateList = [
        { value: 'appdt', label: '거래일자' },
        { value: 'ccdt', label: '취소일자' }
    ];
    const [dateType, setDateType] = useState('appdt');
    const [selectType, setSelectType] = useState('select');
    const selectTypeList = [
        { value: 'select', label: '선택하세요' },
        { value: 'tid', label: 'TID' },
        { value: 'mid', label: 'MID' },
        { value: 'app_no', label: '승인번호' }
    ];

    useEffect(() => {
        setColumnDefs([
            {
                headerCheckboxSelection: true,
                checkboxSelection: true,
                width: 50
            },
            { headerName: 'MID', field: 'MID', width: 130 },
            { headerName: 'TID', field: 'TID', width: 270 },
            { headerName: '금액', field: 'GOODS_AMT', cellRenderer: numberWithCommasGrid, width: 100 },
            { headerName: '승인번호', field: 'APP_NO', width: 100 },
            { headerName: '승인일자', field: 'APP_DT', cellRenderer: dateYYYYMMDDGrid, width: 120 },
            { headerName: '승인시간', field: 'APP_TM', cellRenderer: dateTimeGrid, width: 100 },
            {
                headerName: '승인 재전송',
                field: 'APP_RESEND',
                cellRendererFramework: (params) => (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => NotiResendButton(params)}
                    >
                        재전송
                    </Button>
                ),
                width: 130,
            },
            { headerName: '취소일자', field: 'CC_DT', cellRenderer: dateYYYYMMDDGrid, width: 120 },
            { headerName: '취소시간', field: 'CC_TM', cellRenderer: dateTimeGrid, width: 100 },
            {
                headerName: '취소 재전송',
                field: 'CC_RESEND',
                cellRendererFramework: (params) => (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => ccNotiResendButton(params)}
                    >
                        재전송
                    </Button>
                ),
                width: 130,
            },
            { headerName: '목적지URL', field: 'SND_TARGET', width: 330 }
        ]);
    }, []);


    const dateButton = (e) => {
        const date = new Date();
        const dates = new Date();
        if (e.target.value == '0') {
            date.setDate(date.getDate() - 1);
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(date));
        } else if (e.target.value == '1') {
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(date));
        } else if (e.target.value == '2') {
            date.setDate(date.getDate() - 3);
            dates.setDate(dates.getDate());
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(dates));
        }
    };

    // 노티 리스트 조회
    // 노티 리스트 조회
    const notiResendList = () => {
        let limitDay = dateRange(startDate, endDate);
        if (limitDay > 3) {
            swal({
                text: '시작일자와 종료일자의 간격은 3일 이내여야 합니다.',
                button: {
                    text: '확인',
                },
            });
            return false;
        }

        Swal.showLoading(); // 로딩 시작
        axios
            .post(process.env.REACT_APP_API_HOST + "/mms/notiList", {
                token: cookies.token,
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
                l1_vid: selectInfoList.l1Vid,
                l2_vid: selectInfoList.l2Vid,
                l3_vid: selectInfoList.l3Vid,
                mid: selectInfoList.mid,
                startDate: deleteSpecialChar(startDate),
                endDate: deleteSpecialChar(endDate),
                dateType: dateType,
                selectType: selectType,
                textSelectType: document.getElementById('textSelectType').value
            })
            .then(function (response) {
                if (response.data.res_code !== '0000') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    if (response.data.res_code === '1004') {
                        navigate('/login', { replace: false });
                    }
                } else {
                    setRowData(response.data.data); // 데이터 세팅
                }
            })
            .catch(function (error) {
                // 오류가 발생했을 때 오류 처리
                swal({
                    text: '미지정 오류가 발생했습니다. 다시 시도해 주세요.',
                    button: {
                        text: '확인',
                    },
                });
            })
            .finally(() => {
                Swal.close(); // 요청이 끝나면 로딩 종료
            });
    };


// 개별 승인 재전송
    const NotiResendButton = (params) => {
        Swal.showLoading();
        notiResendUpdate(params.data, '0')
            .then(() => {
                // 성공 시 알림창
                swal({
                    text: '승인 재전송이 완료되었습니다.',
                    button: {
                        text: '확인',
                    },
                });
            })
            .catch((error) => {
                // 실패 시 알림창
                swal({
                    text: `승인 재전송 실패: ${error}`,
                    button: {
                        text: '확인',
                    },
                });
            })
            .finally(() => {
                Swal.close(); // 로딩 종료
            });
    };

// 개별 취소 재전송
    const ccNotiResendButton = (params) => {
        if (params.data.TRX_ST_CD !== '1') {
            swal({
                text: '거래상태가 취소 상태가 아닙니다.',
                button: {
                    text: '확인',
                },
            });
            return false;
        }

        Swal.showLoading();
        notiResendUpdate(params.data, '1')
            .then(() => {
                // 성공 시 알림창
                swal({
                    text: '취소 재전송이 완료되었습니다.',
                    button: {
                        text: '확인',
                    },
                });
            })
            .catch((error) => {
                // 실패 시 알림창
                swal({
                    text: `취소 재전송 실패: ${error}`,
                    button: {
                        text: '확인',
                    },
                });
            })
            .finally(() => {
                Swal.close(); // 로딩 종료
            });
    };

    // 노티 재전송 업데이트 함수
    const notiResendUpdate = (data, type) => {
        return axios
            .post(process.env.REACT_APP_API_HOST + "/mms/notiResendUpdate", {
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
                token: cookies.token,
                tid: data.TID,
                seq: data.URL_SEQ,
                type: type
            })
            .then(function (response) {
                if (response.data.res_code !== '0000') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    if (response.data.res_code === '1004') {
                        navigate('/login', { replace: false });
                    }
                    return Promise.reject(response.data.res_msg); // 실패한 요청 처리
                } else {
                    return Promise.resolve(); // 성공한 요청 처리
                }
            })
            .catch(function (error) {
                return Promise.reject(error.message); // 오류 처리
            });
    };

    // 일괄 승인 재전송 - 순차 처리
    const bulkNotiResend = async () => {
        if (selectedRows.length === 0) {
            swal({
                text: '선택된 항목이 없습니다.',
                button: {
                    text: '확인',
                },
            });
            return;
        }

        Swal.showLoading();

        try {
            console.log('선택된 항목:', selectedRows); // 선택된 항목 확인
            for (const row of selectedRows) { // selectedRows에 저장된 선택된 행만 처리
                await notiResendUpdate(row, '0'); // 각 요청을 순차적으로 처리
            }
            swal({
                text: '모든 승인 재전송이 완료되었습니다.',
                button: {
                    text: '확인',
                },
            });
        } catch (error) {
            swal({
                text: `일부 승인 재전송이 실패하였습니다: ${error}`,
                button: {
                    text: '확인',
                },
            });
        } finally {
            Swal.close();
        }
    };

    // 일괄 취소 재전송 - 순차 처리
    const bulkCcNotiResend = async () => {
        if (selectedRows.length === 0) {
            swal({
                text: '선택된 항목이 없습니다.',
                button: {
                    text: '확인',
                },
            });
            return;
        }

        Swal.showLoading();

        try {
            for (const row of selectedRows) {
                if (row.TRX_ST_CD === '1') {
                    await notiResendUpdate(row, '1'); // 각 요청을 순차적으로 처리
                } else {
                    throw new Error('거래 상태가 취소 상태가 아닌 항목이 있습니다.');
                }
            }
            swal({
                text: '모든 취소 재전송이 완료되었습니다.',
                button: {
                    text: '확인',
                },
            });
        } catch (error) {
            swal({
                text: `일부 취소 재전송이 실패하였습니다: ${error}`,
                button: {
                    text: '확인',
                },
            });
        } finally {
            Swal.close();
        }
    };


    // 선택된 행 저장 (현재 페이지에 있는 항목만 선택)
    const onSelectionChanged = (event) => {
        const api = event.api;
        const pageSize = api.paginationGetPageSize(); // 페이지 사이즈
        const currentPage = api.paginationGetCurrentPage(); // 현재 페이지
        const startRow = currentPage * pageSize; // 시작 행 인덱스
        const endRow = startRow + pageSize; // 종료 행 인덱스

        const selectedNodes = api.getSelectedNodes(); // 선택된 노드 가져오기

        // 현재 페이지에 표시된 행만 선택
        const selectedData = selectedNodes
            .filter(node => node.rowIndex >= startRow && node.rowIndex < endRow) // 현재 페이지 범위 내의 항목만 필터링
            .map(node => node.data);

        setSelectedRows(selectedData); // 선택된 데이터를 상태에 저장
        console.log("선택된 항목 (현재 페이지):", selectedData); // 선택된 항목을 로그로 출력
    };





    return (
        <>
            <div style={{ float: 'right' }}>
                <Button size="small" variant="contained" color="primary" onClick={notiResendList}>
                    조회
                </Button>
            </div>
            <br />
            <br />
            <GroupVidVidGidMidInfo />
            <br />
            <Card>
                <FormControl sx={{ m: 1, width: 250 }}>
                    <Select
                        size="small"
                        defaultValue={'appdt'}
                        value={dateType}
                        inputProps={{
                            id: 'selectDateType',
                        }}
                        onChange={(event) => {
                            setDateType(event.target.value);
                        }}
                        style={{ marginRight: '15px' }}
                    >
                        {dateList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                    <DatePicker
                        value={startDate}
                        inputFormat={"yyyy/MM/dd"}
                        mask={"____/__/__"}
                        onChange={(newValue) => {
                            setStartDate(getFormatDate(newValue));
                        }}
                        renderInput={(params) => <TextField style={{ width: '150px' }} size="small" {...params} />}
                    />
                </LocalizationProvider>
                ~
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                    <DatePicker
                        value={endDate}
                        inputFormat={"yyyy/MM/dd"}
                        mask={"____/__/__"}
                        onChange={(newValue) => {
                            setEndDate(getFormatDate(newValue));
                        }}
                        renderInput={(params) => <TextField style={{ width: '150px' }} size="small" {...params} />}
                    />
                </LocalizationProvider>

                <Button size="medium" style={{ marginLeft: '10px' }} value="0" onClick={dateButton} variant="outlined" color="primary">어제</Button>
                <Button size="medium" style={{ marginLeft: '10px' }} value="1" onClick={dateButton} variant="outlined" color="primary">오늘</Button>
                <Button size="medium" style={{ marginLeft: '10px' }} value="2" onClick={dateButton} variant="outlined" color="primary">3일</Button>

                <FormControl sx={{ m: 1, width: 250 }}>
                    <Select
                        size="small"
                        defaultValue={'select'}
                        value={selectType}
                        inputProps={{
                            id: 'selectTypeList',
                        }}
                        onChange={(event) => {
                            setSelectType(event.target.value);
                        }}
                        style={{ marginLeft: '30px' }}
                    >
                        {selectTypeList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField id="textSelectType" style={{ marginLeft: '30px' }} size="small" variant="outlined" />
            </Card>
            <br />
            <Card>
                <CardHeader title="노티 리스트" />
                <div style={{ marginBottom: '20px' }}>
                    <Button size="small" variant="contained" color="secondary" onClick={bulkNotiResend} style={{ marginRight: '10px' }}>
                        승인 재전송 일괄 실행
                    </Button>
                    <Button size="small" variant="contained" color="secondary" onClick={bulkCcNotiResend}>
                        취소 재전송 일괄 실행
                    </Button>
                </div>
                <div className="ag-theme-alpine" style={{ height: 520, width: '100%' }}>
                    <AgGridReact
                        rowHeight={40}
                        pagination={true}
                        enableCellTextSelection={true}
                        paginationPageSize={10}
                        defaultColDef={{ resizable: true }}
                        debounceVerticalScrollbar={true}
                        rowData={rowData}
                        columnDefs={columnDefs} // 컬럼 정의
                        rowSelection="multiple" // 다중 선택 허용
                        onSelectionChanged={onSelectionChanged} // 선택 변경 시 실행
                    />
                </div>
            </Card>
        </>
    );
};

export default NotiResend;
