import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { quotaMon } from '../../../common/quotaMon';
import { dateYYYYMMDD } from '../../../common/dateYYYYMMDD';
import { dateTime } from '../../../common/dateTime';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {Divider, FormControl} from '@material-ui/core';
import { border, borderBottom } from '@mui/system';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import {dateYYYYMMDDGrid} from "../../../common/grid/dateYYYYMMDDGrid";
import {transStatusGrid} from "../../../common/grid/transStatusGrid";
import {numberWithCommasGrid} from "../../../common/grid/numberWithCommasGrid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Table, TableCell, TableRow} from "@mui/material";
import {
  Card
} from '@material-ui/core';
import TextField from "@mui/material/TextField";


export default (props) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [open, setOpen] = React.useState(false);
  const [selectCancelDate, setSelectCancelDate] = useState({});
  const navigate = useNavigate();

  //취소내역 조회 row data
  const [rowData, setRowData] = useState([]);

  const [cancelMng, setCancelMng] = useState(''); // 취소사유
  const [cancelUser, setCancelUser] = useState('');
  const [cancelType, setCancelType] = useState('');

  const handleCancelMngChange = (event) => {
    setCancelMng(event.target.value);
  };

  const handleCancelUserChange = (event) => {
    setCancelUser(event.target.value);
  };

  const resetFields = () => {
    setCancelMng('');
    setCancelUser('');
    setAmount('');
    setSelectTrxStCdType('2');
  };

  const selectTrxStCdList = (
      [{ value: '1', lavel: '전체취소' }
      ,{ value: '2', lavel: '부분취소' }
      ]);
  const [selectTrxStCdType, setSelectTrxStCdType] = useState('2');
  const handleClickOpen = () => {
    selectCancelDeteilList();
  };

  const handleClose = () => {
    resetFields();
    setOpen(false);
  };

  const requestCancel = () => {
    if (!selectTrxStCdType) {
      swal({
        text: "취소구분을 선택해주세요.",
        button: { text: '확인' },
      });
      return;
    }
    if (!amount || amount === '0') {
      swal({
        text: '취소금액을 입력해주세요.',
        button: { text: '확인' },
      });
      return;
    }
    if (!cancelUser) {
      swal({
        text: '취소요청자를 입력해주세요.',
        button: { text: '확인' },
      });
      return;
    }

    // 확인용 모달
    swal({
      text: "정말 취소하시겠습니까?\n취소 진행 상태는 거래취소 요청 내역에서 확인 가능합니다.",
      buttons: {
        cancel: "아니오",
        confirm: {
          text: "예",
          value: true,
        },
      },
      dangerMode: true,
    }).then((willCancel) => {
      if (willCancel) {
        // "예" 버튼을 눌렀을 때 실행
        axios
            .post(
                process.env.REACT_APP_API_HOST + "/mms/requestCancel",
                {
                  token: cookies.token,
                  usrTypeId: cookies.id,
                  usrType: cookies.type,
                  userId: cookies.userid,
                  tid: cellValue,
                  cancelMng: cancelMng,
                  cancelUser: cancelUser,
                  amount: amount.replace(/,/g, ''),
                  selectTrxStCdType: selectTrxStCdType
                }
            )
            .then(function (response) {
              if (response.data.res_code !== '0000') {
                swal({
                  text: response.data.res_msg,
                  button: { text: '확인' },
                });
                if (response.data.res_code === '1004') {
                  swal({
                    text: response.data.res_msg,
                    button: { text: '확인' },
                  });
                  navigate('/login', { replace: false });
                }
              } else {
                // 요청이 성공적으로 완료되었을 때
                swal({
                  text: '취소 요청이 완료되었습니다.',
                  button: { text: '확인' },
                }).then(() => {
                  resetFields(); // 입력 필드 초기화
                  setOpen(false); // 다이얼로그 닫기
                });
              }
            })
            .catch(function (error) {
              swal({
                text: '미지정 오류',
                button: { text: '확인' },
              });
            });
      } else {
        // "아니오"를 눌렀을 때 실행 (필요에 따라 메시지 추가 가능)
        swal({
          text: '취소 요청이 취소되었습니다.',
          button: { text: '확인' },
        });
      }
    });
  };

  const selectCancelDeteilList = () => {
    axios
      .post(
        process.env.REACT_APP_API_HOST + "/mms/selectCancelDeteilList",

        {
          token: cookies.token,
          usrTypeId: cookies.id,
          usrType: cookies.type,
          userId : cookies.userid,
          tid: cellValue
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      )
      .then(function (response) {
        if (response.data.res_code != '0000') {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          });
          if (response.data.res_code == '1004') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            navigate('/login', { replace: false });
          }
          Swal.close();
        } else {
          setRowData(response.data.data); // rowData에 데이터 설정
          console.log("Row Data:", response.data.data); // rowData 확인
          setSelectCancelDate(response.data.data);
          setCancelType(response.data.data.CANCEL_TYPE);
          setOpen(true);
        }
      }).catch(function (error) {
        swal({
          text: '미지정 오류',
          button: {
            text: '확인',
          },
        });
      });
  }

  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  console.log(cellValue);

  const styles = { fontSize: 11, width: '200%' }

  const [amount, setAmount] = useState('');

  const handleAmountChange = (event) => {
    const value = event.target.value.replace(/,/g, ''); // 기존 콤마 제거
    if (!isNaN(value)) {
      setAmount(new Intl.NumberFormat().format(value)); // 천 단위 콤마 추가
    }
  };



  return (
    <>

      <Button onClick={handleClickOpen}  variant="outlined" style={{width:'100%'}} >취소요청</Button>
      <Dialog
          open={open}
          keepMounted
          maxWidth={false}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"거래 취소 요청"}</DialogTitle>
      <Card>
        <Table size="small" aria-label="a dense table">
        <TableRow>
        <TableCell>취소구분</TableCell>
        <TableCell>
          <FormControl>
            <Select
                size='small'
                value={selectTrxStCdList.value}
                defaultValue={selectTrxStCdType}
                onChange={(event) => {
                  setSelectTrxStCdType(event.target.value);
                }}
                style={{
                  margin: 0,
                  padding: '2px 4px',
                  minWidth: '80px',
                  fontSize: '0.875rem',
                }}
            >
              {selectTrxStCdList?.map((option ,idx) => {
                return (
                    <MenuItem key={idx} value={option.value}>{option.lavel}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </TableCell>
          <TableCell>
            취소금액
          </TableCell>
          <TableCell>
            <TextField
                id="textAmount"
                value={amount}
                onChange={handleAmountChange}
                style={{ minWidth: '200px' }}
                variant="outlined"
                size="small"
                placeholder="금액 입력"
            />
          </TableCell>
        </TableRow>
          <TableRow>
            <TableCell>
              취소사유
            </TableCell>
            <TableCell>
              <TextField id="cancelMng" value={cancelMng} onChange={handleCancelMngChange} style={{ minWidth: '200px' }} variant="outlined" size="small" />
            </TableCell>
            <TableCell>
              취소 요청자
            </TableCell>
            <TableCell>
              <TextField id="cancelUser" value={cancelUser} onChange={handleCancelUserChange} style={{ minWidth: '200px' }} variant="outlined" size="small" />
            </TableCell>
          </TableRow>
        </Table>
      </Card>


        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="ag-theme-alpine" style={{height: 600, width: 800}}>
              <div align="right" style={{paddingBottom : '10px'}}></div>
              <AgGridReact rowData={rowData}
                           enableCellTextSelection={true}
                           defaultColDef={{ resizable: true }}
                           debounceVerticalScrollbar={true}
                           getRowStyle={(params) => {
                           }
                           }
              >
                <AgGridColumn  width={80} headerName = "순번" field="REQ_NO"></AgGridColumn>
                <AgGridColumn  width={120} headerName = "취소일자" field="CC_DT" cellRenderer={dateYYYYMMDDGrid}></AgGridColumn>
                <AgGridColumn  width={120} headerName = "1차PG" field="VAN_CD" ></AgGridColumn>
                <AgGridColumn  width={100} headerName = "정산유형" field="STMT_CYCLE_CD"></AgGridColumn>
                <AgGridColumn  width={100} headerName = "MID" field="MID"></AgGridColumn>
                <AgGridColumn  width={200} headerName = "사업자번호" field="CO_NO"></AgGridColumn>
                <AgGridColumn  width={200} headerName = "상호" field="CO_NM"></AgGridColumn>
                <AgGridColumn  width={100} headerName = "승인번호" field="APP_NO"></AgGridColumn>
                <AgGridColumn  width={100} headerName = "취소유형" field="CANCEL_TYPE"></AgGridColumn>
                <AgGridColumn  width={150} headerName = "취소요청금액" field="CC_AMT" cellRenderer={numberWithCommasGrid}></AgGridColumn>
                <AgGridColumn  width={150} headerName = "진행상태" field="CANCEL_STATUS"></AgGridColumn>
                <AgGridColumn  width={100} headerName = "입금은행" field="BANK_CD"></AgGridColumn>
                <AgGridColumn  width={150} headerName = "입금계좌" field="ACNT_NO"></AgGridColumn>
              </AgGridReact>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={requestCancel}>확인</Button>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};