import React from 'react';
import { Navigate } from 'react-router-dom';

//import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import PrepayLayout from './layouts/PrepayLayout';


// import AccountView from './views/account/AccountView';
// import CustomerListView from './views/customer/CustomerListView';
import LoginView from './views/auth/LoginView';
import NotFoundView from './views/errors/NotFoundView';
// import ProductListView from './views/product/ProductListView';
import RegisterPasswordView from './views/auth/RegisterPasswordView';
import TransView from './views/trans/TransView';
import MainView from './views/main/MainView';
import MidFeeView from './views/fee/MidFeeView';
import MidInfoView from './views/MidInfo/MidInfoView';
import BankReportView from './views/bankReport/BankReportView';
import PayMentView from './views/payment/PayMentView';
import SmsView from './views/SmsView';
import SmsPayView from './views/SmsView/SmsPayView';
import NotiView from './views/noti/NotiView'
import PayMentListView from './views/payment';
import MsmbsView from './views/msmbs/MsmbsView';
import TaxView from './views/tax/TaxView';
import AgencyView from './views/agency/AgencyView';
import NoticeView from './views/notice';
import CancelView from "./views/cancel/CancelView";
import ApplyMidView from "./views/applymid/ApplyMidView";
import ApplyResultView from "./views/applymidresult/ApplyResultView";


const routes = (isLoggedIn) => [
  {
    className :'test',
    path: 'app',
    element: isLoggedIn ? <PrepayLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'main', element: <MainView /> },
      { path: 'midfee', element: <MidFeeView /> },
      { path: 'midinfo', element: <MidInfoView /> },
      { path: 'trans', element: <TransView /> },
      { path: 'applymid', element: <ApplyMidView /> },
      { path: 'applymidresult', element: <ApplyResultView /> },
      { path: 'bankreport', element: <BankReportView /> },
      { path: 'manualpay', element: <PayMentView /> },
      { path: 'manualpaylist', element: <PayMentListView /> },
      { path: 'smspaylist', element: <SmsView /> },
      { path: 'smspay', element: <SmsPayView /> },
      { path: 'notiresend', element: <NotiView /> },
      { path: 'msmbssearch', element: <MsmbsView /> },
      { path: 'surtax', element: <TaxView /> },
      { path: 'agencyreport', element: <AgencyView /> },
      { path: 'notice', element: <NoticeView />},
      { path: 'cancEL', element: <CancelView />},
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  // {
  //   path: 'app_origin',
  //   element: <DashboardLayout />,
  //   children: [
  //     { path: 'account', element: <AccountView /> },
  //     { path: 'customers', element: <CustomerListView /> },
  //     { path: 'dashboard', element: <DashboardView /> },
  //     { path: 'products', element: <ProductListView /> },
  //     { path: 'settings', element: <SettingsView /> },
  //     { path: '*', element: <Navigate to="/404" /> }
  //   ]
  // },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'password', element: <RegisterPasswordView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;