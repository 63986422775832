import React, { useState, useContext, useEffect, useRef  } from 'react';
import {
    Card,
    Divider,
    CardHeader, colors
} from '@material-ui/core';
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl } from '@material-ui/core';
import { getFormatDate } from '../../../common/getFormatDate';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { checkPgGrid } from '../../../common/grid/checkPgGrid';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { MyContext } from '../../../App';
import { dateRange } from '../../../common/dateRange';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router-dom';
import { dateYYYYMMDDGrid } from '../../../common/grid/dateYYYYMMDDGrid';
import { dateTimeGrid } from '../../../common/grid/dateTimeGrid';
import { numberWithCommasGrid } from '../../../common/grid/numberWithCommasGrid';
import { quotaMoanGrid } from '../../../common/grid/quotaMonGrid';
import { transStatusGrid } from '../../../common/grid/transStatusGrid';
import { transExcel } from '../../../common/excel/transExcel';
import { getCurrentDateYYYYMMDD } from '../../../common/getCurrentDateYYYYMMDD';
import ko from 'date-fns/locale/ko';
import './ApplyMid.css';

const ApplyMid = () => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [vidOptions, setVidOptions] = useState([]); // 결제 유형
    const [typeList, setTypeList] = useState([]);
    const [l2VidOptions, setL2VidOptions] = useState([]); // 대리점
    const [l3VidOptions, setL3VidOptions] = useState([]); // 딜러
    const [selectedVid, setSelectedVid] = useState(""); // 선택된 결제 유형
    const [selectedL2Vid, setSelectedL2Vid] = useState(""); // 선택된 대리점
    const [selectedL3Vid, setSelectedL3Vid] = useState(""); // 선택된 딜러
    const [filteredData, setFilteredData] = useState([]); //
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [openModal, setOpenModal] = useState(false); // 모달 상태
    const [currentRow, setCurrentRow] = useState(null); // 현재 선택된 행
    const [selectedRowIndex, setSelectedRowIndex] = useState(null); // 선택된 행 인덱스
    const [fileData, setFileData] = useState({}); // 첨부된 파일 데이터
    const [businessType, setBusinessType] = useState("");
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [payIdCdOptions, setPayIdCdOptions] = useState([]);
    const [prepayflg, setprepayflg] = useState([]);
    const [bankList, setBankList] = useState([])
    const [loading, setLoading] = useState(false);
    const [appType, setAppType] = useState("");
    const [payIdCd, setPayIdCd] = useState(false);

    const handleOpenModal = async (params) => {
        try {
            // 현재 선택된 행의 데이터 저장
            setCurrentRow(params.data);

            // BUSINESS_TYPE 가져오기 및 저장
            setBusinessType(params.data.BUSINESS_TYPE || "");
            setAppType(params.data.APP_TYPE || "");

            // 직정산 여부 계산 및 저장
            const isDirectSettlement =
                Array.isArray(payIdCdOptions) && payIdCdOptions.includes("직정산");
            setPayIdCd(isDirectSettlement);

            // 선택된 행의 인덱스와 REQ_NO 저장
            setSelectedRowIndex(params.rowIndex);
            setSelectedRowId(params.data.REQ_NO);


            // fileData 초기화 및 rowData 동기화
            setFileData({
                "사업자등록증": params.data.IMG_NM1 ? params.data.IMG_NM1.split(",").map((file) => ({ fileName: file.trim() })) : [],
                "상품사진": params.data.IMG_NM2 ? params.data.IMG_NM2.split(",").map((file) => ({ fileName: file.trim() })) : [],
                "기타서류": params.data.IMG_NM3 ? params.data.IMG_NM3.split(",").map((file) => ({ fileName: file.trim() })) : [],
                "계약서류": params.data.IMG_NM4 ? params.data.IMG_NM4.split(",").map((file) => ({ fileName: file.trim() })) : [],
            });

            // 모달 열기
            setOpenModal(true);
        } catch (error) {
            console.error("파일 조회 중 오류 발생:", error);
            alert("파일 조회 중 문제가 발생했습니다. 다시 시도해주세요.");
        }
    };


    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedRowIndex(null);
        setFileData({}); // 파일 데이터 초기화
    };

// 파일 선택 핸들러
    const handleFileChange = (e, fileType) => {
        const files = Array.from(e.target.files);
        console.log("업로드된 파일:", files); // 파일 구조를 확인
        if (files.length > 0) {
            setFileData((prev) => ({
                ...prev,
                [fileType]: files.map((file) => ({
                    file,
                    fileName: file.name,
                    fileSize: file.size, // 파일 크기 저장
                })),
            }));
        }
    };
   /* const handleFileChange = (e, fileType) => {
        const file = e.target.files[0];
        if (file) {
            setFileData((prev) => ({
                ...prev,
                [fileType]: {
                    file: file,
                    fileName: file.name, // 파일명 저장
                },
            }));
        }
    };*/


// 파일 등록 핸들러
    const handleFileUpload = async () => {
        if (selectedRowId !== null) {
            const isBusiness = rowData.find((row) => row.REQ_NO === selectedRowId)?.BUSINESS_TYPE || false;
            const apptype = rowData.find((row) => row.REQ_NO === selectedRowId)?.APP_TYPE || false;
            const paycdid = Array.isArray(payIdCdOptions) && payIdCdOptions.includes("직정산");

            const sizeChk = (file) => {
                const maxSize = 30 * 1024 * 1024; // 30MB
                return file.fileSize <= maxSize;
            };

            // 필수 입력 필드 및 파일 크기 체크
            if (isBusiness === "1" && !((apptype === "키오스크" && paycdid) || (apptype === "단말기" && paycdid))) {
                /*if (!fileData["사업자등록증"] || fileData["사업자등록증"].length === 0) {
                    alert("사업자등록증이 필수입니다.");
                    return;
                }
                if (!fileData["상품사진"] || fileData["상품사진"].length === 0) {
                    alert("상품사진이 필수입니다.");
                    return;
                }
                if (!fileData["계약서류"] || fileData["계약서류"].length === 0) {
                    alert("계약서류 필수입니다.");
                    return;
                }*/
                if (!fileData["사업자등록증"] || fileData["사업자등록증"].length === 0) {
                    swal({
                        text: "사업자등록증이 필수입니다.",
                        button: {
                            text: "확인",
                        },
                    });
                    return;
                }

                if (!fileData["상품사진"] || fileData["상품사진"].length === 0) {
                    swal({
                        text: "상품사진이 필수입니다.",
                        button: {
                            text: "확인",
                        },
                    });
                    return;
                }
                if (!(apptype === "수기" && !paycdid)) {
                    if (!fileData["계약서류"] || fileData["계약서류"].length === 0) {
                        swal({
                            text: "계약서류 필수입니다.",
                            button: {
                                text: "확인",
                            },
                        });
                        return;
                    }
                }
                if (!fileData["사업자등록증"].every(sizeChk)) {
                    swal({
                        text: "사업자등록증 파일 크기가 30MB를 초과합니다.",
                        button: {
                            text: "확인",
                        },
                    });
                    return;
                }
                if (!fileData["상품사진"].every(sizeChk)) {
                    swal({
                        text: "상품사진 파일 크기가 30MB를 초과합니다.",
                        button: {
                            text: "확인",
                        },
                    });
                    return;
                }
                if (!(apptype === "수기" && !paycdid)) {
                    if (!fileData["계약서류"].every(sizeChk)) {
                        swal({
                            text: "계약서류 파일 크기가 30MB를 초과합니다.",
                            button: {
                                text: "확인",
                            },
                        });
                        return;
                    }
                }
                /* if (!fileData["계약서류"] || fileData["계약서류"].length === 0) {
                    swal({
                        text: "계약서류 필수입니다.",
                        button: {
                            text: "확인",
                        },
                    });
                    return;
                }*/
               /* if (!fileData["사업자등록증"].every(sizeChk)) {
                    alert("사업자등록증 파일 크기가 30MB를 초과합니다.");
                    return;
                }
                if (!fileData["상품사진"].every(sizeChk)) {
                    alert("상품사진 파일 크기가 30MB를 초과합니다.");
                    return;
                }
                if (!fileData["계약서류"].every(sizeChk)) {
                    alert("계약서류 파일 크기가 30MB를 초과합니다.");
                    return;
                }*/
            }else if((apptype === "키오스크" && paycdid) || (apptype === "단말기" && paycdid)) {
                if (!fileData["계약서류"] || fileData["계약서류"].length === 0) {
                    alert("계약서류 필수입니다.");
                    return;
                }
                if (!fileData["계약서류"].every(sizeChk)) {
                    alert("계약서류 파일 크기가 30MB를 초과합니다.");
                    return;
                }
            } else {
                if (!fileData["상품사진"] || fileData["상품사진"].length === 0) {
                    alert("상품사진이 필수입니다.");
                    return;
                }
                if (!(apptype === "수기" && !paycdid)) {
                    if (!fileData["계약서류"] || fileData["계약서류"].length === 0) {
                        alert("계약서류 필수입니다.");
                        return;
                    }
                }
                if (!fileData["상품사진"].every(sizeChk)) {
                    alert("상품사진 파일 크기가 30MB를 초과합니다.");
                    return;
                }
                if (!(apptype === "수기" && !paycdid))  {
                    if (!fileData["계약서류"].every(sizeChk)) {
                        alert("계약서류 파일 크기가 30MB를 초과합니다.");
                        return;
                    }
                }
            }

            if (fileData["기타서류"] && !fileData["기타서류"].every(sizeChk)) {
                alert("기타서류 파일 크기가 30MB를 초과합니다.");
                return;
            }

            console.log("파일 크기 검사 통과");

            try {
                // 서버에서 파일 목록 조회
                const searchResponse = await axios.get(`${process.env.REACT_APP_API_HOST}/contfile/search`);
                const existingFiles = searchResponse.data.map((file) => {
                    const fullPath = file.fileName || "";
                    return fullPath.substring(fullPath.lastIndexOf("/") + 1); // 파일명만 추출
                });

                console.log("기존 파일 목록 (파일명만):", existingFiles);

                // 중복 파일 확인 및 처리 함수
                const resolveDuplicateFileName = (fileName) => {
                    let newFileName = fileName;
                    let counter = 1;
                    const fileExtension = fileName.includes(".") ? fileName.substring(fileName.lastIndexOf(".")) : "";
                    const baseName = fileName.replace(fileExtension, "");

                    while (existingFiles.includes(newFileName)) {
                        newFileName = `${baseName}-${counter}${fileExtension}`;
                        counter++;
                    }

                    // 기존 파일 이름 배열에 새 이름 추가
                    existingFiles.push(newFileName);
                    return newFileName;
                };

                // 파일 이름 업데이트
                Object.keys(fileData).forEach((key) => {
                    fileData[key] = fileData[key].map((file) => ({
                        ...file,
                        fileName: resolveDuplicateFileName(file.fileName), // 중복 처리된 파일 이름
                    }));
                });

            } catch (error) {
                console.error("서버 파일 조회 중 오류 발생:", error);
                alert("서버 파일 조회 중 문제가 발생했습니다.");
                return;
            }

            const formData = new FormData();

            // FormData에 파일 추가
            const appendFiles = (key) => {
                if (fileData[key]) {
                    fileData[key].forEach((file) => {
                        const updatedFile = new File([file.file], file.fileName, { type: file.file.type });
                        formData.append("DATA_FILE", updatedFile);
                        formData.append("fileNm", updatedFile.name);
                    });
                }
            };

            appendFiles("사업자등록증");
            appendFiles("상품사진");
            appendFiles("기타서류");
            appendFiles("계약서류");

            axios
                .post(`${process.env.REACT_APP_API_HOST}/contfile/upload`, formData, {
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                    withCredentials: false,
                })
                .then((uploadResponse) => {
                    console.log("파일 업로드 성공:", uploadResponse.data);
                    setRowData((prevRowData) =>
                        prevRowData.map((row) =>
                            row.REQ_NO === selectedRowId
                                ? {
                                    ...row,
                                    IMG_NM1: fileData["사업자등록증"]?.map((file) => file.fileName).join(", ") || row.IMG_NM1 || "",
                                    IMG_NM2: fileData["상품사진"]?.map((file) => file.fileName).join(", ") || row.IMG_NM2 || "",
                                    IMG_NM3: fileData["기타서류"]?.map((file) => file.fileName).join(", ") || row.IMG_NM3 || "",
                                    IMG_NM4: fileData["계약서류"]?.map((file) => file.fileName).join(", ") || row.IMG_NM4 || "",
                                }
                                : row
                        )
                    );
                    handleCloseModal();
                    alert("파일 업로드가 완료되었습니다.");
                })
                .catch((error) => {
                    console.error("파일 업로드 실패:", error);
                });
        }
    };




    /*const handleFileUpload =  async () => {
        if (selectedRowId !== null) {
            const isBusiness = rowData.find((row) => row.REQ_NO === selectedRowId)?.BUSINESS_TYPE || false;
            console.log("selectedRowId:", selectedRowId);
            console.log("isBusiness 상태:", isBusiness);
            console.log("fileData 상태:", JSON.stringify(fileData, null, 2));

            const sizeChk = (file) => {
                const maxSize = 30 * 1024 * 1024; // 30MB
                console.log(`파일 이름: ${file.fileName}, 파일 크기: ${file.fileSize}, 제한: ${maxSize}`);
                return file.fileSize <= maxSize;
            };

            // 필수 입력 필드 및 파일 크기 체크
            if (isBusiness === "1") {
                console.log("사업자 파일 검사 시작");
                if (!fileData["사업자등록증"] || fileData["사업자등록증"].length === 0) {
                    alert("사업자등록증이 필수입니다.");
                    return;
                }
                if (!fileData["상품사진"] || fileData["상품사진"].length === 0) {
                    alert("상품사진이 필수입니다.");
                    return;
                }

                if (!fileData["사업자등록증"].every(sizeChk)) {
                    alert("사업자등록증 파일 크기가 30MB를 초과합니다.");
                    return;
                }
                if (!fileData["상품사진"].every(sizeChk)) {
                    alert("상품사진 파일 크기가 30MB를 초과합니다.");
                    return;
                }
            } else {
                console.log("일반 파일 검사 시작");
                if (!fileData["상품사진"] || fileData["상품사진"].length === 0) {
                    alert("상품사진이 필수입니다.");
                    return;
                }

                if (!fileData["상품사진"].every(sizeChk)) {
                    alert("상품사진 파일 크기가 30MB를 초과합니다.");
                    return;
                }
            }

            if (fileData["기타서류"] && !fileData["기타서류"].every(sizeChk)) {
                alert("기타서류 파일 크기가 30MB를 초과합니다.");
                return;
            }

            console.log("파일 크기 검사 통과");

            try {
                // 서버에서 파일 목록 조회
                const searchResponse = await axios.get(`${process.env.REACT_APP_API_HOST}/contfile/search`);
                const existingFiles = searchResponse.data.map((file) => file.fileName);


                // 업로드하려는 파일 이름 추출
                const uploadFileNames = [
                    ...(fileData["사업자등록증"]?.map((file) => file.fileName) || []),
                    ...(fileData["상품사진"]?.map((file) => file.fileName) || []),
                    ...(fileData["기타서류"]?.map((file) => file.fileName) || []),
                ];

                // 중복된 파일 확인
                const duplicates = uploadFileNames.filter((fileName) =>
                    existingFiles.includes(`pg/mbs_info/${fileName}`)
                );

                if (duplicates.length > 0) {
                    swal({
                        title: "중복된 파일 이름 발견",
                        text: `파일이 이미 존재합니다:\n${duplicates.join(", ")}\n\n파일 이름을 수정한 후 다시 업로드해주세요.`,
                        icon: "warning",
                        buttons: {
                            confirm: {
                                text: "확인",
                                value: true,
                            },
                        },
                    });
                    return; // 업로드 중단
                }
            } catch (error) {
                console.error("서버 파일 조회 중 오류 발생:", error);
                alert("서버 파일 조회 중 문제가 발생했습니다.");
                return;
            }


            const formData = new FormData();

            // FormData에 파일 추가
            const appendFiles = (key) => {
                if (fileData[key]) {
                    fileData[key].forEach((file) => {
                        formData.append("DATA_FILE", file.file);
                        formData.append("fileNm", file.fileName);
                    });
                }
            };

            appendFiles("사업자등록증");
            appendFiles("상품사진");
            appendFiles("기타서류");
            axios
                .post(process.env.REACT_APP_API_HOST + "/contfile/upload", formData, {
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                    withCredentials: false,  // 쿠키 또는 인증 정보를 포함하는 경우
                })
                .then((uploadResponse) => {
                    console.log("파일 업로드 성공:", uploadResponse.data);
                    setRowData((prevRowData) =>
                        prevRowData.map((row) =>
                            row.REQ_NO === selectedRowId
                                ? {
                                    ...row,
                                    IMG_NM1: fileData["사업자등록증"]?.map((file) => file.fileName).join(", ") || row.IMG_NM1 || "",
                                    IMG_NM2: fileData["상품사진"]?.map((file) => file.fileName).join(", ") || row.IMG_NM2 || "",
                                    IMG_NM3: fileData["기타서류"]?.map((file) => file.fileName).join(", ") || row.IMG_NM3 || "",
                                }
                                : row
                        )
                    );
                    handleCloseModal();
                    alert("파일 업로드가 완료되었습니다.");
                })
                .catch((error) => {
                    console.error("파일 업로드 실패:", error);
                });
        }
    };*/

    const handleFileDelete = (fileName, fileType) => {
        console.log("삭제 요청된 파일 이름:", fileName);

        // 로컬 상태에서만 파일 삭제
        setFileData((prev) => ({
            ...prev,
            [fileType]: prev[fileType]?.filter((file) => file.fileName !== fileName),
        }));

        setRowData((prev) =>
            prev.map((row) =>
                row.REQ_NO === selectedRowId
                    ? {
                        ...row,
                        [`IMG_NM${fileType === "사업자등록증" ? 1 : fileType === "상품사진" ? 2 : 3}`]: (row[
                            `IMG_NM${fileType === "사업자등록증" ? 1 : fileType === "상품사진" ? 2 : 3}`
                            ] || "")
                            .split(",")
                            .filter((name) => name.trim() !== fileName)
                            .join(","),
                    }
                    : row
            )
        );
    };


    /*const handleFileDelete = async (fileName,fileType) => {
        try {
            console.log("전송될 파일 이름:", fileName);
            console.log("전송될 파일 타입:", fileType);

            const response = await axios.post(
                `${process.env.REACT_APP_API_HOST}/contfile/delete`,
                fileName,// JSON 형식으로 파일 이름 전달
                {
                    headers: {
                        "Content-Type": "text/plain",
                    },
                }
            );

            console.log("서버 응답:", response.data);

            if (response.data.fileOk === "success") {
                alert(`파일 ${fileName}이 삭제되었습니다.`);
                setFileData((prev) => ({
                    ...prev,
                    [fileType]: prev[fileType].filter((file) => file.fileName !== fileName),
                }));

                // rowData 상태 업데이트
                setRowData((prev) =>
                    prev.map((row) => {
                        if (row.REQ_NO === selectedRowId) {
                            const key = fileType === "사업자등록증" ? "IMG_NM1" : fileType === "상품사진" ? "IMG_NM2" : "IMG_NM3";
                            return {
                                ...row,
                                [key]: (row[key] || "")
                                    .split(",")
                                    .filter((name) => name.trim() !== fileName)
                                    .join(","),
                            };
                        }
                        return row;
                    })
                );
            } else {
                console.error("파일 삭제 실패:", response.data);
            }
        } catch (error) {
            console.error("파일 삭제 중 오류:", error);
        }
    };*/









    /* const handleFileUpload = () => {
         if (selectedRowId !== null) {
             // 사업자 여부를 확인하기 위한 값 (예: rowData에서 가져오기)
             const isBusiness = rowData.find((row) => row.REQ_NO === selectedRowId)?.IS_BUSINESS || false;

             // 필수 입력 필드 확인
             const sizeChk = (file) => {
                 const maxSize = 20 * 1024 * 1024; // 20MB 제한
                 return file.size <= maxSize;
             };

             // 필수 입력 필드 확인 및 파일 크기 체크
             if (isBusiness) {
                 if (!fileData["사업자등록증"] || !fileData["사업자등록증"].fileName) {
                     alert("사업자등록증이 필수입니다.");
                     return;
                 }
                 if (!fileData["상품사진"] || !fileData["상품사진"].fileName) {
                     alert("상품사진이 필수입니다.");
                     return;
                 }

                 if (!sizeChk(fileData["사업자등록증"].file)) {
                     alert("사업자등록증 파일 크기가 20MB를 초과합니다.");
                     return;
                 }
                 if (!sizeChk(fileData["상품사진"].file)) {
                     alert("상품사진 파일 크기가 20MB를 초과합니다.");
                     return;
                 }
             } else {
                 if (!fileData["상품사진"] || !fileData["상품사진"].fileName) {
                     alert("상품사진이 필수입니다.");
                     return;
                 }

                 if (!sizeChk(fileData["상품사진"].file)) {
                     alert("상품사진 파일 크기가 20MB를 초과합니다.");
                     return;
                 }
             }

             if (fileData["기타서류"] && !sizeChk(fileData["기타서류"].file)) {
                 alert("기타서류 파일 크기가 20MB를 초과합니다.");
                 return;
             }


             const formData = new FormData();

             if (fileData["사업자등록증"]) {
                 formData.append("DATA_FILE", fileData["사업자등록증"].file);
                 formData.append("fileNm", fileData["사업자등록증"].fileName);
             }
             if (fileData["상품사진"]) {
                 formData.append("DATA_FILE", fileData["상품사진"].file);
                 formData.append("fileNm", fileData["상품사진"].fileName);
             }
             if (fileData["기타서류"]) {
                 formData.append("DATA_FILE", fileData["기타서류"].file);
                 formData.append("fileNm", fileData["기타서류"].fileName);
             }

             axios
                 .post(process.env.REACT_APP_API_HOST + "/contfile/upload", formData, {
                     headers: {
                         "Content-Type": "multipart/form-data",
                     },
                     maxContentLength: Infinity,
                     maxBodyLength: Infinity,
                 })
                 .then((uploadResponse) => {
                     console.log("파일 업로드 성공:", uploadResponse.data);
                     setRowData((prevRowData) =>
                         prevRowData.map((row) =>
                             row.REQ_NO === selectedRowId
                                 ? {
                                     ...row,
                                     IMG_NM1: fileData["사업자등록증"]?.fileName || row.IMG_NM1 || "",
                                     IMG_NM2: fileData["상품사진"]?.fileName || row.IMG_NM2 || "",
                                     IMG_NM3: fileData["기타서류"]?.fileName || row.IMG_NM3 || "",
                                 }
                                 : row
                         )
                     );

                     // 모달창 닫기
                     setFileData({});
                     handleCloseModal();
                     alert("파일 업로드가 성공적으로 완료되었습니다.");
                 })
                 .catch((error) => {
                     console.error("파일 업로드 실패:", error);
                     alert("파일 업로드 중 오류가 발생했습니다.");
                 });
         }
     };*/

// 파일 유효성 검증
    const validateFiles = () => {
        if (businessType === "1") {
            // 사업자
            if (!fileData["사업자등록증"] || !fileData["상품사진"]) {
                alert("사업자등록증과 상품사진은 필수입니다.");
                return false;
            }
        } else if (businessType === "2") {
            // 비사업자
            if (!fileData["상품사진"]) {
                alert("상품사진은 필수입니다.");
                return false;
            }
        }
        return true;
    };


    const currentDate = new Date();

    const gridRef = useRef(null); // 그리드 API에 접근하기 위한 참조 생성
    const [selectedRows, setSelectedRows] = useState([]);
    const columnApi = useRef(null);

    const columnsToHide = [
        "CAT_ID",
        "COL_NM",
        "REP_NM",
        "GID",
        "HP_NO",
        "ADDR_NO",
        "UID_YN",
        "BUSINESS_TYPE",
        "LMT_INSTMN",
        "LMT_SINGLE_AMT",
        "LMT_MONTH_AMT",
        "INSURANCE_FLG",
        "CO_NO",
        "VAT_MARK_FLG",
        "CARD_FOR_YN",
        "BS_KIND",
        "PAYMENT_URL",
        "REMARK",
    ];

    const onGridReady = (params) => {
        gridRef.current = params.api; // API 참조 저장
        columnApi.current = params.columnApi; // columnApi 저장
    };

    const columnDefs = [
        { headerName: "순번", field: "REQ_NO", width: 80 },
        {
            headerName: "승인PG사",
            field: "APP_VAN_CD",
            width: 120,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_APP_VAN_CD=== true, // COL_NM 에러 플래그
            },
            cellEditorParams: (params) => ({
                values: filteredData.length > 1
                    ? filteredData.map((option) => option.PGNM)
                    : ["선택하세요", ...filteredData.map((option) => option.PGNM)],
            }),
            valueGetter: (params) => params.data.APP_VAN_CD || "선택하세요",
            onCellClicked: (params) => {
                params.api.startEditingCell({
                    rowIndex: params.rowIndex,
                    colKey: "APP_VAN_CD",
                });
            },
        },
        {
            headerName: "결제유형",
            field: "APP_TYPE",
            editable: false,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: typeList.length > 0 ? ["선택하세요", ...typeList] : ["선택하세요"],
            },
            valueGetter: (params) => params.data.APP_TYPE || "선택하세요", // 기본값 설정
            onCellValueChanged: (params) => {
                const selectedType = params.data.APP_TYPE;

                // 결제방식 옵션 매핑
                const paymentOptionsMap = {
                    "수기": ["선택하세요","구인증", "비인증","인증"],
                    "단말기": ["선택하세요","PG승인", "VAN 승인"],
                    "키오스크": ["선택하세요","PG승인(키오스크)"],
                    "온라인 인증": ["선택하세요","인증결제"],
                };

                const options = paymentOptionsMap[selectedType] || ["선택하세요"];

                // APP_PAY 값 초기화 및 드롭다운 옵션 업데이트
                params.node.setDataValue("APP_PAY", options[0] || "선택하세요");

                // API의 컬럼 정의 갱신
                params.api.setColumnDefs(
                    columnDefs.map((col) =>
                        col.field === "APP_PAY"
                            ? { ...col, cellEditorParams: { values: ["선택하세요", ...options] } }
                            : col
                    )
                );
            },
        },
        {
            headerName: "결제방식",
            field: "APP_PAY",
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_APP_PAY === true, // COL_NM 에러 플래그
            },
            cellEditorParams: (params) => {
                const paymentOptionsMap = {
                    "수기": ["구인증", "비인증","인증"],
                    "단말기": ["PG승인", "VAN 승인"],
                    "키오스크": ["PG승인(키오스크)"],
                    "온라인 인증": ["인증결제"],
                };

                // 현재 APP_TYPE에 따른 옵션 가져오기
                const selectedType = params.data.APP_TYPE || "선택하세요";
                const options = paymentOptionsMap[selectedType] || ["선택하세요"];
                return { values: ["선택하세요", ...options] };
            },
            onCellValueChanged : (params) => {
                const { APP_TYPE, APP_PAY } = params.data;

                if (APP_TYPE === "단말기") {
                    if (APP_PAY === "PG승인") {
                        params.node.setDataValue("CARD_FOR_YN", "N"); // 기본값: 미사용
                        params.data._isCardEditable = true; // 사용/미사용 선택 가능
                    } else if (APP_PAY === "VAN 승인") {
                        params.node.setDataValue("CARD_FOR_YN", "Y"); // 기본값: 사용
                        params.data._isCardEditable = false; // 값 고정
                    }
                } else if (APP_TYPE === "수기") {
                    params.node.setDataValue("CARD_FOR_YN", "N"); // 기본값: 미사용
                    params.data._isCardEditable = false; // 값 고정
                } else {
                    params.data._isCardEditable = true; // 기본값: 선택 가능
                }
            },
        },
        {
            headerName: "CAT_ID",
            field: "CAT_ID",
            width: 120,
            editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_CAT_ID === true, // CAT_ID 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                CAT_ID: params.newValue,
                                _hasError_CAT_ID: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "상호명",
            field: "COL_NM",
            width: 100,
            editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_COL_NM === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                COL_NM: params.newValue,
                                _hasError_COL_NM: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        { headerName: "대표자명", field: "REP_NM", width: 120, editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_REP_NM === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                REP_NM: params.newValue,
                                _hasError_REP_NM: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        { headerName: "인증결제 URL", field: "PAYMENT_URL", width: 120, editable: true },
        { headerName: "연락처", field: "HP_NO", width: 120, editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_HP_NO === true, // COL_NM 에러 플래그
            },
            valueSetter: (params) => {
                const newValue = params.newValue;

                // 숫자만 허용, 숫자가 아닌 경우 필드 초기화
                if (/^\d*$/.test(newValue)) {
                    params.data.HP_NO = newValue; // 유효한 숫자일 경우 업데이트
                } else {
                    params.data.HP_NO = ""; // 숫자가 아닌 경우 필드 초기화
                }

                return true; // 항상 true 반환하여 업데이트를 적용
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                HP_NO : params.newValue,
                                _hasError_HP_NO: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        { headerName: "주소", field: "ADDR_NO", width: 120, editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_ADDR_NO === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                ADDR_NO : params.newValue,
                                _hasError_ADDR_NO: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        { headerName: "GID", field: "GID", width: 120, editable: true },
        { headerName: "업종(결제상품명)", field: "BS_KIND", width: 120, editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_BS_KIND === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                BS_KIND : params.newValue,
                                _hasError_BS_KIND: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        { headerName: "가맹점 아이디 발급 여부",
            field: "UID_YN",
            width: 120,
            editable: true ,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Y", "N"], // 선택 가능 값
            },
            valueFormatter: (params) => {
                // 값에 따라 '사용' 또는 '미사용'으로 표시
                return params.value === "Y" ? "신청" : "미신청";
            },
        },
        {
            headerName: "사업자여부",
            field: "BUSINESS_TYPE",
            width: 100,
            editable: (params) => params.data.APP_TYPE === "수기" || params.data.APP_TYPE === "키오스크" || params.data.APP_TYPE === "단말기", // 수기와 키오스크에서 수정 가능
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["선택", "1", "2"], // 선택 가능 값
            },
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_BUSINESS_TYPE === true, // 에러 플래그 기반 스타일링
            },
            valueFormatter: (params) => {
                switch (params.value) {
                    case "1":
                        return "사업자";
                    case "2":
                        return "비사업자";
                    default:
                        return "선택"; // 기본값
                }
            },
            valueSetter: (params) => {
                const newValue = params.newValue;

                const isYoungJungSo = filteredData.length > 0 && filteredData[0].MS_USE_FLG === "1";

                // 키오스크 직정산 조건
                const isKioskJikJungsan =
                    params.data.APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const isKioskjaeJungsan =
                    params.data.APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("재정산");

                const isSugiJikJungsan =
                    params.data.APP_TYPE === "수기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const danJikJungsan =
                    params.data.APP_TYPE  === "단말기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                if (isYoungJungSo) {
                    params.data.BUSINESS_TYPE = "1"; // 사업자 고정
                    return true;
                }

                if (isKioskJikJungsan  || danJikJungsan) {
                    params.data.BUSINESS_TYPE = "1"; // 사업자 고정
                    return true;
                }

                // 키오스크 직정산일 경우 강제로 "사업자" 설정
                if (isKioskJikJungsan) {
                    params.data.BUSINESS_TYPE = "1"; // 사업자
                    params.data.CO_NO = vidOptions?.[0]?.CO_NO || "";
                } else if(isKioskjaeJungsan)  {
                    params.data.BUSINESS_TYPE = "1"; // 사업자
                } else {
                    // 기존 로직
                    if (!newValue || newValue === "선택") {
                        params.data.BUSINESS_TYPE = ""; // "선택"인 경우 빈 값 처리
                    } else {
                        params.data.BUSINESS_TYPE = newValue; // 정상적인 값 설정

                        // 사업자인 경우 CO_NO 기본값 설정
                        if (newValue === "1") {
                            params.data.CO_NO = vidOptions?.[0]?.CO_NO || "";
                        } else if (newValue === "2") {
                            params.data.CO_NO = ""; // 비사업자인 경우 CO_NO 초기화
                        }
                    }
                }
                return true;
            },
            onCellValueChanged: (params) => {
                const { BUSINESS_TYPE, APP_TYPE } = params.data;

                // 수기, 키오스크, 또는 직정산일 때 로직 적용
                const isSugiJikJungsan =
                    APP_TYPE === "수기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const isKioskJikJungsan =
                    APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const danJikJungsan =
                    APP_TYPE === "단말기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const isKioskjaeJungsan =
                    APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("재정산");

                if (APP_TYPE === "수기" || APP_TYPE === "키오스크"  || isSugiJikJungsan || isKioskJikJungsan ||danJikJungsan || isKioskjaeJungsan) {
                    setRowData((prevRowData) =>
                        prevRowData.map((row) =>
                            row.REQ_NO === params.data.REQ_NO
                                ? {
                                    ...row,
                                    CO_NO: BUSINESS_TYPE === "1" ? row.CO_NO : "", // 비사업자인 경우 초기화
                                    REGISTER_NUM: BUSINESS_TYPE === "2" ? row.REGISTER_NUM : "", // 사업자인 경우 초기화
                                    _isCoNoEditable: BUSINESS_TYPE === "1", // 사업자인 경우 활성화
                                    _isRegisterNumEditable: BUSINESS_TYPE === "2", // 비사업자인 경우 활성화
                                }
                                : row
                        )
                    );
                }
            },
        },
        {
            headerName: "사업자번호",
            field: "CO_NO",
            width: 100,
            editable: (params) => {
                const isSugiJikJungsan =
                    params.data.APP_TYPE === "수기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const isKioskJikJungsan =
                    params.data.APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const danJikJungsan =
                    params.data.APP_TYPE === "단말기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const isKioAndjaeJungsan =
                    params.data.APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("재정산");




                return (
                    params.data.APP_TYPE === "키오스크" || // 키오스크는 항상 편집 가능
                    (params.data.BUSINESS_TYPE === "1" &&
                        (params.data.APP_TYPE === "수기" || isSugiJikJungsan || isKioskJikJungsan || danJikJungsan || isKioAndjaeJungsan))
                );
            },
            cellClassRules: {
                "error-cell": (params) => {
                    const isSugiJikJungsan =
                        params.data.APP_TYPE === "수기" &&
                        Array.isArray(payIdCdOptions) &&
                        payIdCdOptions.includes("직정산");

                    const isKioskJikJungsan =
                        params.data.APP_TYPE === "키오스크" &&
                        Array.isArray(payIdCdOptions) &&
                        payIdCdOptions.includes("직정산");

                    const danJikJungsan =
                        params.data.APP_TYPE === "단말기" &&
                        Array.isArray(payIdCdOptions) &&
                        payIdCdOptions.includes("직정산");

                    const isKioAndjaeJungsan =
                        params.data.APP_TYPE === "키오스크" &&
                        Array.isArray(payIdCdOptions) &&
                        payIdCdOptions.includes("재정산");

                    return (
                        (params.data.APP_TYPE === "수기" && params.data.BUSINESS_TYPE === "1") ||
                        ((isSugiJikJungsan || isKioskJikJungsan || danJikJungsan || isKioAndjaeJungsan) && params.data.BUSINESS_TYPE === "1")
                    ) && params.data._hasError_CO_NO;

                   /* return (
                        ((params.data.APP_TYPE === "수기" && params.data.BUSINESS_TYPE === "1") ||
                            params.data.APP_TYPE === "키오스크" ||
                            isSugiJikJungsan ||
                            isKioskJikJungsan) &&
                        params.data._hasError_CO_NO
                    );*/
                },
            },
            valueSetter: (params) => {
                const newValue = params.newValue;

                // 숫자만 허용, 숫자가 아닌 경우 필드 초기화
                if (/^\d*$/.test(newValue)) {
                    params.data.CO_NO = newValue; // 유효한 숫자일 경우 업데이트
                } else {
                    params.data.CO_NO = ""; // 숫자가 아닌 경우 필드 초기화
                }

                return true; // 항상 true 반환하여 업데이트를 적용
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                CO_NO: params.newValue,
                                _hasError_CO_NO: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "주민등록번호",
            field: "REGISTER_NUM",
            width: 100,
            editable: (params) => {
                const isSugiJikJungsan =
                    params.data.APP_TYPE === "수기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const isKioskJikJungsan =
                    params.data.APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const danJikJungsan =
                    params.data.APP_TYPE === "단말기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                return (
                    (params.data.APP_TYPE === "수기" && params.data._isRegisterNumEditable) ||
                    ((isSugiJikJungsan || isKioskJikJungsan || danJikJungsan) && params.data.BUSINESS_TYPE === "2")
                );
            },
            cellClassRules: {
                "error-cell": (params) => {
                    const isSugiJikJungsan =
                        params.data.APP_TYPE === "수기" &&
                        Array.isArray(payIdCdOptions) &&
                        payIdCdOptions.includes("직정산");

                    const isKioskJikJungsan =
                        params.data.APP_TYPE === "키오스크" &&
                        Array.isArray(payIdCdOptions) &&
                        payIdCdOptions.includes("직정산");

                    const danJikJungsan =
                        params.data.APP_TYPE === "단말기" &&
                        Array.isArray(payIdCdOptions) &&
                        payIdCdOptions.includes("직정산");

                    return (
                        (params.data.APP_TYPE === "수기" && params.data.BUSINESS_TYPE === "2") ||
                        ((isSugiJikJungsan || isKioskJikJungsan || danJikJungsan) && params.data.BUSINESS_TYPE === "2")
                    ) && params.data._hasError_REGISTER_NUM;
                },
            },
            valueSetter: (params) => {
                const newValue = params.newValue;

                // 숫자만 허용, 숫자가 아닌 경우 필드 초기화
                if (/^\d*$/.test(newValue)) {
                    params.data.REGISTER_NUM = newValue; // 유효한 숫자일 경우 업데이트
                } else {
                    params.data.REGISTER_NUM = ""; // 숫자가 아닌 경우 필드 초기화
                }

                return true; // 항상 true 반환하여 업데이트를 적용
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                REGISTER_NUM: params.newValue,
                                _hasError_REGISTER_NUM: !params.newValue || params.newValue.trim() === "", // 값이 없거나 빈 문자열이면 에러
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "이메일",
            field: "EMAIL",
            width: 120,
            editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_EMAIL === true, // 이메일 에러 플래그
            },
            onCellValueChanged: (params) => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 이메일 정규식
                const newValue = params.newValue || ""; // 새로운 이메일 값

                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                EMAIL: newValue,
                                _hasError_EMAIL: !emailRegex.test(newValue), // 이메일 형식 검증
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "은행",
            field: "bank",
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: bankList.map((bank) => bank.description), // DESC1만 드롭다운 옵션에 표시
            },
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_bank=== true, // COL_NM 에러 플래그
            },
            valueSetter: (params) => {
                const selectedBank = bankList.find((bank) => bank.description === params.newValue);
                if (selectedBank) {
                    params.data.bank = selectedBank.description; // DESC1 저장
                    params.data.bankCode = selectedBank.code; // CODE 저장
                } else {
                    params.data.bank = ""; // 초기화
                    params.data.bankCode = ""; // 초기화
                }
                return true;
            },
            valueFormatter: (params) => {
                return params.value || "선택하세요"; // DESC1만 표시
            },
        },
        { headerName: "입금계좌번호", field: "ACT_NO", width: 120, editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_ACT_NO === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                ACT_NO : params.newValue,
                                _hasError_ACT_NO: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        { headerName: "예금주", field: "ACT_NM", width: 120, editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_ACT_NM === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                ACT_NM : params.newValue,
                                _hasError_ACT_NM: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        { headerName: "일반수수료", field: "N_FEE", width: 120, editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_N_FEE === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                N_FEE : params.newValue,
                                _hasError_N_FEE: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "영중소 계약여부",
            field: "MS_USE_FLG",
            width: 200,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Y", "N"], // 선택 가능 값
            },
            valueFormatter: (params) => {
                // 값에 따라 '계약' 또는 '미계약'으로 표시
                return params.value === "Y" ? "계약" : "미계약";
            },
            onCellValueChanged: (params) => {
                const isContracted = params.newValue === "Y";
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                _isJFeeEditable: isContracted, // 수수료 필드 활성화 여부
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "중소3 수수료",
            field: "J_FEE3",
            width: 120,
            //editable:true,
            editable: () => {
                return filteredData.length > 0 && filteredData[0].MS_USE_FLG === "1";
            },
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_J_FEE3 === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                J_FEE3: params.newValue,
                                _hasError_J_FEE3:
                                    params.data.MS_USE_FLG === "1" &&
                                    (!params.newValue || params.newValue.trim() === ""), // 계약 시 필수
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "중소2 수수료",
            field: "J_FEE2",
            width: 120,
            //editable:true,
            editable: () => {
                return filteredData.length > 0 && filteredData[0].MS_USE_FLG === "1";
            },
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_J_FEE2 === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                J_FEE2: params.newValue,
                                _hasError_J_FEE2:
                                    params.data.MS_USE_FLG === "1" &&
                                    (!params.newValue || params.newValue.trim() === ""),
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "중소1 수수료",
            field: "J_FEE1",
            width: 120,
            //editable:true,
            editable: () => {
                return filteredData.length > 0 && filteredData[0].MS_USE_FLG === "1";
            },
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_J_FEE1 === true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                J_FEE1: params.newValue,
                                _hasError_J_FEE1:
                                    params.data.MS_USE_FLG === "1" &&
                                    (!params.newValue || params.newValue.trim() === ""),
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "영세 수수료",
            field: "Y_FEE",
            width: 120,
            //editable:true,
            editable: () => {
                return filteredData.length > 0 && filteredData[0].MS_USE_FLG === "1";
            },
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_Y_FEE=== true, // COL_NM 에러 플래그
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                Y_FEE: params.newValue,
                                _hasError_Y_FEE:
                                    params.data.MS_USE_FLG === "1" &&
                                    (!params.newValue || params.newValue.trim() === ""),
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "할부개월",
            field: "LMT_INSTMN",
            width: 100,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["선택", "1", "2", "3", "4", "5", "6"], // 선택 가능 값
            },
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_LMT_INSTMN, // 할부개월 에러 플래그에 따라 색상 설정
            },
            valueFormatter: (params) => {
                switch (params.value) {
                    case "1":
                        return "일시불";
                    case "2":
                        return "2개월";
                    case "3":
                        return "3개월";
                    case "4":
                        return "4개월";
                    case "5":
                        return "5개월";
                    case "6":
                        return "6개월";
                    default:
                        return "선택"; // 기본값
                }
            },
            valueSetter: (params) => {
                if (!params.newValue || params.newValue === "선택") {
                    params.data.LMT_INSTMN = ""; // 기본값 "선택" 처리 시 빈 값으로 저장
                } else {
                    params.data.LMT_INSTMN = params.newValue; // 선택된 값 저장
                }
                return true;
            }
        },
        {
            headerName: "1회결제한도",
            field: "LMT_SINGLE_AMT",
            width: 100,
            editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_LMT_SINGLE_AMT, // COL_NM 에러 플래그
            },
            valueSetter: (params) => {
                const newValue = params.newValue.replace(/[^\d]/g, ""); // 숫자만 추출
                params.data.LMT_SINGLE_AMT = newValue; // 숫자만 저장
                return true; // 항상 true 반환하여 업데이트 적용
            },
            valueFormatter: (params) => {
                const value = params.value || "";
                return value ? `${value}만원` : ""; // 값이 있으면 뒤에 '만원' 추가
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                LMT_SINGLE_AMT: params.newValue.replace(/[^\d]/g, ""), // 숫자만 저장
                                _hasError_LMT_SINGLE_AMT: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "월결제한도",
            field: "LMT_MONTH_AMT",
            width: 100,
            editable: true,
            cellClassRules: {
                "error-cell": (params) => params.data._hasError_LMT_MONTH_AMT, // COL_NM 에러 플래그
            },
            valueSetter: (params) => {
                const newValue = params.newValue.replace(/[^\d]/g, ""); // 숫자만 추출
                params.data.LMT_MONTH_AMT = newValue; // 숫자만 저장
                return true; // 항상 true 반환하여 업데이트 적용
            },
            valueFormatter: (params) => {
                const value = params.value || "";
                return value ? `${value}만원` : ""; // 값이 있으면 뒤에 '만원' 추가
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                LMT_MONTH_AMT: params.newValue.replace(/[^\d]/g, ""), // 숫자만 저장
                                _hasError_LMT_MONTH_AMT: !params.newValue || params.newValue.trim() === "",
                            }
                            : row
                    )
                );
            },
        },
        { headerName: "보증보험증권 신청 여부",
            field: "INSURANCE_FLG",
            width: 100,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Y", "N"], // 선택 가능 값
            },
            valueFormatter: (params) => {
                // 값에 따라 텍스트 표시
                switch (params.value) {
                    case "Y":
                        return "신청";
                    case "N":
                        return "미신청";
                    default:
                        return "미신청";
                }
            },
        },
        { headerName: "현금영수증 사용여부",
            field: "VAT_MARK_FLG",
            width: 200,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Y", "N"], // 선택 가능 값
            },
            valueFormatter: (params) => {
                // 값에 따라 '사용' 또는 '미사용'으로 표시
                return params.value === "Y" ? "사용" : "미사용";
            },
        },
        { headerName: "해외카드사용여부",
            field: "CARD_FOR_YN",
            width: 200,
            editable: (params) => params.data._isCardEditable !== false,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Y", "N"], // 선택 가능 값
            },
            valueFormatter: (params) => {
                // 값에 따라 '사용' 또는 '미사용'으로 표시
                return params.value === "Y" ? "사용" : "미사용";
            },
            onCellValueChanged: (params) => {
                const isContracted2 = params.newValue === "Y";
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                _isJFeeEditable: isContracted2, // 수수료 필드 활성화 여부
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "해외카드 수수료",
            field: "O_FEE",
            width: 120,
            editable: (params) => params.data._isJFeeEditable,
            cellClassRules: {
                "error-cell": (params) => params.data.CARD_FOR_YN === "Y" && params.data._hasError_O_FEE === true,
            },
            onCellValueChanged: (params) => {
                setRowData((prevRowData) =>
                    prevRowData.map((row) =>
                        row.REQ_NO === params.data.REQ_NO
                            ? {
                                ...row,
                                O_FEE: params.newValue,
                                _hasError_O_FEE:
                                    params.data.CARD_FOR_YN === "Y" &&
                                    (!params.newValue || params.newValue.trim() === ""),
                            }
                            : row
                    )
                );
            },
        },
        {
            headerName: "파일 첨부",
            field: "file",
            cellRendererFramework: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleOpenModal(params)}
                    style={{
                        textTransform: "none",
                        pointerEvents: "auto", // 버튼 동작 허용
                        cursor: "pointer", // 포인터 커서
                        userSelect: "none", // 텍스트 선택 방지
                    }}
                >
                    파일 첨부
                </Button>
            ),
            width: 150,
            editable: false,
        },
        { headerName: "비고", field: "REMARK", width: 120, editable: true },
        {
            headerName: "삭제",
            cellRenderer: "deleteButtonRenderer",
            width: 100,
        },
    ];

    const [columnState, setColumnState] = useState(null);

    useEffect(() => {
        // 컴포넌트 마운트 시 컬럼 상태 로드
        const savedState = JSON.parse(localStorage.getItem("columnState"));
        if (savedState) {
            setColumnState(savedState);
        }
    }, []);

    // 컬럼 상태가 변경될 때 로컬 스토리지에 저장
    useEffect(() => {
        if (columnState) {
            localStorage.setItem("columnState", JSON.stringify(columnState));
        }
    }, [columnState]);








    //그리드 사이즈
  const rowHeight = 40;
  //거래 리스트 data
  const [rowData, setRowData] = useState([]);

  //거래 리스트 total
  const [rowDataTotal, setRowDataTotal] = useState({});

  //결제유형 VID SELECT박스 조회
  const fetchVidOptions  = () => {
    Swal.showLoading();
    axios.
      post(process.env.REACT_APP_API_HOST + "/mms/selectVidList"
        , {
          token: cookies.token,
          userId : cookies.userid,
          usrTypeId: cookies.id,
          usrType: cookies.type,
          vid: selectedVid
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      ).then(function (response) {
        if (response.data.res_code != '0000') {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          });
          if (response.data.res_code == '1004') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            navigate('/login', { replace: false });
          }
          Swal.close();
        } else {


            const vidListWithDesc = response.data.bankList.map((item) => ({
                code: item.CODE1, // CODE1 값을 매핑
                description: item.DESC1, // DESC1 값을 매핑
            }));
            setBankList(vidListWithDesc);
            setVidOptions(response.data.data);
            /*setFilteredData(response.data.data); */
            console.log("필터링된 데이터:", setTypeList);
            Swal.close();
        }
      }).catch(function (error) {
        Swal.close();
        swal({
          text: '미지정 오류',
          button: {
            text: '확인',
          },
        });
      });
  }



    useEffect(() => {
        console.log("rowData:", rowData);
        console.log("columnDefs:", columnDefs);
    }, [rowData, columnDefs]);


    const selectVgrp = () => {
        Swal.showLoading();
        axios
            .post(process.env.REACT_APP_API_HOST + "/mms/selectVgrpList", {
                token: cookies.token,
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
                vid: selectedVid
            })
            .then((response) => {
                if (response.data.res_code !== "0000") {
                    swal({
                        text: response.data.res_msg,
                        button: { text: "확인" },
                    });
                } else {
                    console.log("Filtered Data:", response.data.data); // 데이터 출력
                    const payIdCdOptions = response.data.data.map((item) => item.PAY_ID_CD || "");
                    setPayIdCdOptions(payIdCdOptions);
                    const formattedTypeList = response.data.typeList.map((item) => item.RESULT);
                    setTypeList(formattedTypeList);
                    // 필요한 상태에 데이터 저장
                    setFilteredData(response.data.data);
                }
                Swal.close();
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.close();
                swal({ text: "조회 중 오류가 발생했습니다.", button: { text: "확인" } });
                console.error(error);
            });
    };

    useEffect(() => {
        console.log("payIdCdOptions 상태 업데이트:", payIdCdOptions);
    }, [payIdCdOptions]);

    useEffect(() => {
        console.log("prepayflg 상태 업데이트:", prepayflg);
    }, [prepayflg]);

    const fetchL2VidOptions = (l1Vid) => {
        Swal.showLoading();
        axios
            .post(process.env.REACT_APP_API_HOST + "/mms/selectl2vid", {
                l1Vid,
                token: cookies.token,
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
            })
            .then((response) => {
                if (response.data.res_code !== "0000") {
                    Swal.fire("Error", response.data.res_msg, "error");
                } else {
                    setL2VidOptions(response.data.data);
                }
                Swal.close();
            })
            .catch(() => {
                Swal.close();
                Swal.fire("Error", "대리점 조회 중 오류가 발생했습니다.", "error");
            });
    };

    // 딜러 조회
    const fetchL3VidOptions = (l1Vid, l2Vid) => {
        Swal.showLoading();
        axios
            .post(process.env.REACT_APP_API_HOST + "/mms/selectl3vid", {
                l1Vid,
                l2Vid,
                token: cookies.token,
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
            })
            .then((response) => {
                if (response.data.res_code !== "0000") {
                    Swal.fire("Error", response.data.res_msg, "error");
                } else {
                    setL3VidOptions(response.data.data);
                }
                Swal.close();
            })
            .catch(() => {
                Swal.close();
                Swal.fire("Error", "딜러 조회 중 오류가 발생했습니다.", "error");
            });
    };

    useEffect(() => {
        fetchVidOptions();
    }, []);

    // 결제 유형 선택 핸들러
    const handleVidChange = (event) => {
        const value = event.target.value;
        console.log("선택된 VID:", value);
        setSelectedVid(value);
        setSelectedL2Vid("");
        setSelectedL3Vid("");
        setL2VidOptions([]);
        setL3VidOptions([]);
        setLoading(true);
        /* setFilteredData([
             {
                 PGNM: "-",
                 MANUAL_PAY_FLG: "-",
                 PAY_ID_CD: "-",
                 STMT_CYCLE_CD: "-",
                 N_FEE: "-",
                 J_FEE3: "-",
                 J_FEE2: "-",
                 J_FEE1: "-",
                 Y_FEE: "-",
                 O_FEE: "-",
             },
         ]);*/
        setFilteredData([]);
        setRowData([]);
        if (value) {
            fetchL2VidOptions(value);
            selectVgrp(value);
        }
    };

    // 대리점 선택 핸들러
    const handleL2VidChange = (event) => {
        const value = event.target.value;
        setSelectedL2Vid(value);
        setSelectedL3Vid("");
        setL3VidOptions([]);
        if (value && selectedVid) {
            fetchL3VidOptions(selectedVid, value);
        }
    };

    const DeleteButtonRenderer = () => {
        return (
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    try {
                        // rowData 상태 업데이트
                        setRowData((prevRowData) => {
                            if (!Array.isArray(prevRowData) || prevRowData.length === 0) {
                                console.warn("삭제할 데이터가 없습니다.");
                                return prevRowData; // 그대로 반환하여 상태 유지
                            }

                            // 마지막 항목의 REQ_NO를 찾기
                            const lastRowId = prevRowData[prevRowData.length - 1].REQ_NO;
                            console.log("삭제 요청된 마지막 REQ_NO:", lastRowId);

                            // 마지막 항목 제거
                            const updatedData = prevRowData.filter((row) => row.REQ_NO !== lastRowId);
                            console.log("삭제 후 rowData 상태:", updatedData);
                            return updatedData;
                        });
                    } catch (error) {
                        console.error("삭제 중 오류 발생:", error);
                    }
                }}
                style={{ backgroundColor: "red", color: "white" }}
            >
                삭제
            </Button>
        );
    };

    const addRow = () => {
        if (!selectedVid || selectedVid.trim() === "") {
            alert("결제유형을 선택해 주세요");
            return;
        }

        if (selectedL2Vid === "" || selectedL2Vid === null) {
            alert("대리점을 선택해 주세요");
            return;
        }

        if (selectedL3Vid === "" || selectedL3Vid === null) {
            alert("딜러를 선택해 주세요");
            return;
        }

        setRowData((prevRowData) => {
            //const msUseFlgValue = filteredData?.[0]?.MS_USE_FLG === "1" ? "Y" : "N";

            const isKioskJikJungsan =
                typeList.includes("키오스크") &&
                Array.isArray(payIdCdOptions) &&
                payIdCdOptions.includes("직정산");

            const isKioskjaeJungsan =
                typeList.includes("키오스크") &&
                Array.isArray(payIdCdOptions) &&
                payIdCdOptions.includes("재정산");

            const isSugiJikJungsan =
                typeList.includes("수기") &&
                Array.isArray(payIdCdOptions) &&
                payIdCdOptions.includes("직정산");

            const SugiJaeJungsan =
                typeList.includes("수기") &&
                Array.isArray(payIdCdOptions) &&
                payIdCdOptions.includes("재정산");


            const isDanJikJungsan =
                typeList.includes("단말기") &&
                Array.isArray(payIdCdOptions) &&
                payIdCdOptions.includes("직정산");

            const isYoungJungSo =
                filteredData.length > 0 && filteredData[0].MS_USE_FLG === "1";

            const newRow = {
                REQ_NO: prevRowData.length + 1, // 행 번호
                APP_VAN_CD: "선택하세요",       // 승인 PG사
                APP_TYPE: typeList[0] || "선택하세요", // 결제 유형
                APP_PAY: "선택하세요",          // 결제 방식
                CAT_ID: "",                    // 카테고리 ID
                COL_NM: "",                    // 상호명
                REP_NM: "",                    // 대표자명
                HP_NO: "",                     // 연락처
                ADDR_NO: "",                   // 주소
                BS_KIND: "",                   // 업종
                CO_NO: vidOptions?.[0]?.CO_NO || "",      // 사업자번호
                BUSINESS_TYPE: (isYoungJungSo && (isKioskJikJungsan || isKioskjaeJungsan || isSugiJikJungsan || isDanJikJungsan || SugiJaeJungsan)) ? "1" : "",
                UID_YN: "N",                   // UID 사용 여부
                VAT_MARK_FLG: "N",             // 부가세 표시 여부
                CARD_FOR_YN: "N",              // 해외카드 사용 여부
                LMT_INSTMN: "",                // 할부 개월
                LMT_SINGLE_AMT: "",            // 1회 결제 한도
                LMT_MONTH_AMT: "",             // 월 결제 한도
                INSURANCE_FLG: "N",            // 보증 보험증권 신청 여부
                REMARK: "",                    // 비고
                GID: "",                       // 그룹 ID
                PAYMENT_URL: "",               // 결제 URL
                N_FEE: "",                     // 일반 수수료율
                //MS_USE_FLG: msUseFlgValue,
                J_FEE3: "",                    // 중소3 수수료율
                J_FEE2: "",                    // 중소2 수수료율
                J_FEE1: "",                    // 중소1 수수료율
                Y_FEE: "",                     // 영세 수수료율
                O_FEE: "",                     // 해외카드 수수료율
                file: "",
                bankOptions: bankList,
                //_isJFeeEditable: msUseFlgValue === "Y",
            };

            const updatedRowData = [...prevRowData, newRow];

            // APP_TYPE에 따라 컬럼 가시성 업데이트
            if (columnApi.current) {
                setColumnVisibility(columnApi.current, newRow.APP_TYPE, payIdCdOptions);
            } else {
                console.error("columnApi is not initialized");
            }

            return updatedRowData;
        });
    };

    const updateColumnDefs = (newColumnDefs) => {
        if (gridRef.current) {
            gridRef.current.setColumnDefs(newColumnDefs);
        } else {
            console.error("Grid API is not initialized");
        }
    };


    const setColumnVisibility = (columnApi, type, payIdCd) => {
        if (!columnApi) {
            console.error("columnApi is not initialized");
            return;
        }

        console.log("Updating column visibility for type:", type);

        console.log("Updating column visibility for type:", type, "and PAY_ID_CD:", payIdCd);

        const visibilityMap = {
            "수기": [
                "COL_NM", "REP_NM", "HP_NO", "ADDR_NO", "BS_KIND",
                "UID_YN", "BUSINESS_TYPE", "CO_NO","REGISTER_NUM", "LMT_INSTMN",
                "LMT_SINGLE_AMT", "LMT_MONTH_AMT","file",
            ],
            "단말기": [
                "CAT_ID", "COL_NM", "REP_NM", "HP_NO", "ADDR_NO",
                "BS_KIND", "CARD_FOR_YN",
            ],
            "키오스크": [
                "CAT_ID", "COL_NM", "REP_NM", "HP_NO", "ADDR_NO",
                "BS_KIND", "CO_NO", "CARD_FOR_YN",
            ],
            "온라인 인증": [
                "GID", "COL_NM", "REP_NM", "HP_NO", "ADDR_NO",
                "BS_KIND", "UID_YN", "BUSINESS_TYPE", "CO_NO",
                "PAYMENT_URL", "LMT_INSTMN", "LMT_SINGLE_AMT",
                "LMT_MONTH_AMT"
            ],
            "수기직정산": [
                "COL_NM","REP_NM","HP_NO","ADDR_NO","BS_KIND",
                "UID_YN","BUSINESS_TYPE","CO_NO","REGISTER_NUM","EMAIL","bank","ACT_NO","ACT_NM","N_FEE",
                "J_FEE3","J_FEE2","J_FEE1","Y_FEE",
                "LMT_INSTMN","LMT_SINGLE_AMT","LMT_MONTH_AMT","file"

            ],
            "키오스크직정산": [
                "CAT_ID","COL_NM","REP_NM","HP_NO","ADDR_NO","BS_KIND","UID_YN","BUSINESS_TYPE",
                "CO_NO","EMAIL","bank","ACT_NO","ACT_NM","N_FEE","J_FEE3","J_FEE2","J_FEE1","Y_FEE",
                ,"CARD_FOR_YN","O_FEE","file"

            ],
            "단말기직정산": [
                "CAT_ID","COL_NM","REP_NM","HP_NO","ADDR_NO","BS_KIND","UID_YN","BUSINESS_TYPE",
                "CO_NO","REGISTER_NUM","EMAIL","bank","ACT_NO","ACT_NM","N_FEE","J_FEE3","J_FEE2","J_FEE1","Y_FEE",
                ,"CARD_FOR_YN","O_FEE","file"

            ],
            "키오스크재정산": [
                "CAT_ID","COL_NM","REP_NM","HP_NO","ADDR_NO","BS_KIND","UID_YN","BUSINESS_TYPE",
                "CO_NO","EMAIL","bank","ACT_NO","ACT_NM","N_FEE","J_FEE3","J_FEE2","J_FEE1","Y_FEE",
                ,"CARD_FOR_YN","O_FEE"

            ],
        };

        // 항상 표시할 컬럼
        const alwaysVisibleColumns = ["APP_VAN_CD","APP_TYPE", "APP_PAY", "REQ_NO"];

        const allColumns = columnApi.getAllColumns();

        if (!allColumns) {
            console.error("No columns found");
            return;
        }

        // 모든 컬럼 숨기기
        allColumns.forEach((column) => {
            const colId = column.getColId();
            columnApi.setColumnVisible(colId, false);
        });

        // 항상 표시할 컬럼 보이기
        alwaysVisibleColumns.forEach((colId) => {
            columnApi.setColumnVisible(colId, true);
        });

        // 선택된 결제방식에 해당하는 컬럼 표시
        const mapKey =
            type === "수기" && Array.isArray(payIdCd) && payIdCd.includes("직정산")
                ? "수기직정산"
                : type === "키오스크" && Array.isArray(payIdCd) && payIdCd.includes("직정산")
                    ? "키오스크직정산"
                : type === "단말기" && Array.isArray(payIdCd) && payIdCd.includes("직정산")
                    ? "단말기직정산"
                : type === "키오스크" && Array.isArray(payIdCd) && payIdCd.includes("재정산")
                    ? "키오스크재정산"
                    : type;

        // 선택된 결제방식에 해당하는 컬럼 표시
        if (visibilityMap[mapKey]) {
            visibilityMap[mapKey].forEach((colId) => {
                columnApi.setColumnVisible(colId, true);
            });
        }


        console.log("Updated column visibility for:", mapKey);
    };

    useEffect(() => {
        console.log("rowData 상태가 변경되었습니다:", rowData);
    }, [rowData]);

    useEffect(() => {
        if (selectedVid) {
            selectVgrp(selectedVid);
        }
    }, [selectedVid]);


    //등록버튼
    const requestMid = () => {

        const validateRowData = (rows) => {
            let hasErrors = false;
            const msUseFlg = filteredData[0].MS_USE_FLG;

            const updatedRows = rows.map((row) => {
                const { APP_TYPE, BUSINESS_TYPE } = row;
                const emailValue = row.EMAIL;


                // 결제 유형(APP_TYPE)에 따른 필수 필드 조건 설정
                const hasError_CAT_ID = (APP_TYPE === "단말기" || APP_TYPE === "키오스크") &&  (!row.CAT_ID || row.CAT_ID.trim() === "");
                const hasError_COL_NM = APP_TYPE !== "온라인 인증" && (!row.COL_NM || row.COL_NM.trim() === "");
                const hasError_REP_NM = APP_TYPE !== "온라인 인증" && (!row.REP_NM || row.REP_NM.trim() === "");
                const hasError_HP_NO = !row.HP_NO || row.HP_NO.trim() === "";
                const hasError_ADDR_NO = !row.ADDR_NO || row.ADDR_NO.trim() === "";
                const hasError_BS_KIND = !row.BS_KIND || row.BS_KIND.trim() === "";
                const hasError_LMT_INSTMN =
                    (APP_TYPE === "수기" || APP_TYPE === "온라인 인증") && (!row.LMT_INSTMN || row.LMT_INSTMN.trim() === "");
                const hasError_LMT_SINGLE_AMT =
                    (APP_TYPE === "수기" || APP_TYPE === "온라인 인증") && (!row.LMT_SINGLE_AMT || row.LMT_SINGLE_AMT.trim() === "");
                const hasError_LMT_MONTH_AMT =
                    (APP_TYPE === "수기" || APP_TYPE === "온라인 인증") && (!row.LMT_MONTH_AMT || row.LMT_MONTH_AMT.trim() === "");

                const hasError_APP_PAY = !row.APP_PAY || row.APP_PAY.trim() === "" || row.APP_PAY === "선택하세요"

                const hasError_APP_VAN_CD = !row.APP_VAN_CD || row.APP_VAN_CD.trim() === "" || row.APP_VAN_CD === "선택하세요";


                const isSugiAndJikJungsan =
                    APP_TYPE === "수기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");
                
                const isKioAndJikJungsan =
                    APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const danJikJungsan =
                    APP_TYPE === "단말기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const isKioAndjaeJungsan =
                    APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("재정산");


                const hasError_BUSINESS_TYPE =
                    (isSugiAndJikJungsan || isKioAndJikJungsan || APP_TYPE === "수기" || danJikJungsan || isKioAndjaeJungsan) && // 직정산일 때만 체크
                    (!row.BUSINESS_TYPE || row.BUSINESS_TYPE.trim() === "");

                // 이메일 검증 정규식
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                // 검증 조건들
                const hasError_CO_NO =
                    BUSINESS_TYPE === "1" && // 사업자인 경우
                    (!row.CO_NO || row.CO_NO.trim() === "");

                const hasError_REGISTER_NUM =
                    BUSINESS_TYPE === "2" &&
                    !isKioAndJikJungsan && // 키오스크 직정산인 경우 제외
                    (!row.REGISTER_NUM || row.REGISTER_NUM.trim() === "");

                const hasError_EMAIL =
                    (isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                    (!emailValue || !emailRegex.test(emailValue));

                const hasError_N_FEE =
                    (isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                    (!row.N_FEE || row.N_FEE.trim() === "");

                const hasError_J_FEE3 =
                    /*(isContracted && (isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan)) &&*/
                    (msUseFlg === "1") &&
                    ((isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                        (!row.J_FEE3 || row.J_FEE3.trim() === ""));


                const hasError_J_FEE2 =
                    /*(isContracted && (isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan)) &&*/
                    (msUseFlg === "1") &&
                    ((isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                        (!row.J_FEE2 || row.J_FEE2.trim() === ""));


                const hasError_J_FEE1 =
                    /*(isContracted && (isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan)) &&*/
                    (msUseFlg === "1") &&
                    ((isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                        (!row.J_FEE1 || row.J_FEE1.trim() === ""));



                const hasError_Y_FEE =
                    (msUseFlg === "1") &&
                    ((isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                        (!row.Y_FEE || row.Y_FEE.trim() === ""));

                const hasError_ACT_NO =
                    (isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                    (!row.ACT_NO || row.ACT_NO.trim() === "");

                const hasError_ACT_NM =
                    (isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                    (!row.ACT_NM || row.ACT_NM.trim() === "");

                const hasError_bank =
                    (isSugiAndJikJungsan || isKioAndJikJungsan || danJikJungsan || isKioAndjaeJungsan) &&
                    (!row.bank || row.bank.trim() === "" || row.bank === "선택하세요");




                // 에러 플래그 설정
                if (
                    hasError_CAT_ID ||
                    hasError_COL_NM ||
                    hasError_REP_NM ||
                    hasError_HP_NO ||
                    hasError_ADDR_NO ||
                    hasError_BS_KIND ||
                    hasError_CO_NO ||
                    hasError_REGISTER_NUM ||
                    hasError_LMT_INSTMN ||
                    hasError_LMT_SINGLE_AMT ||
                    hasError_LMT_MONTH_AMT ||
                    hasError_BUSINESS_TYPE ||
                    hasError_EMAIL ||
                    hasError_N_FEE ||
                    hasError_J_FEE3 ||
                    hasError_J_FEE2 ||
                    hasError_J_FEE1 ||
                    hasError_Y_FEE ||
                    hasError_APP_PAY ||
                    hasError_APP_VAN_CD ||
                    hasError_ACT_NO ||
                    hasError_ACT_NM ||
                    hasError_bank

                ) {
                    hasErrors = true;
                }

                return {
                    ...row,
                    _hasError_CAT_ID: hasError_CAT_ID,
                    _hasError_COL_NM: hasError_COL_NM,
                    _hasError_REP_NM: hasError_REP_NM,
                    _hasError_HP_NO: hasError_HP_NO,
                    _hasError_ADDR_NO: hasError_ADDR_NO,
                    _hasError_BS_KIND: hasError_BS_KIND,
                    _hasError_CO_NO: hasError_CO_NO,
                    _hasError_REGISTER_NUM: hasError_REGISTER_NUM,
                    _hasError_LMT_INSTMN: hasError_LMT_INSTMN,
                    _hasError_LMT_SINGLE_AMT: hasError_LMT_SINGLE_AMT,
                    _hasError_LMT_MONTH_AMT: hasError_LMT_MONTH_AMT,
                    _hasError_BUSINESS_TYPE:hasError_BUSINESS_TYPE,
                    _hasError_EMAIL: hasError_EMAIL,
                    _hasError_N_FEE: hasError_N_FEE,
                    _hasError_J_FEE3:hasError_J_FEE3,
                    _hasError_J_FEE2:hasError_J_FEE2,
                    _hasError_J_FEE1:hasError_J_FEE1,
                    _hasError_Y_FEE:hasError_Y_FEE,
                    _hasError_APP_PAY:hasError_APP_PAY,
                    _hasError_APP_VAN_CD:hasError_APP_VAN_CD,
                    _hasError_ACT_NO: hasError_ACT_NO,
                    _hasError_ACT_NM: hasError_ACT_NM,
                    _hasError_bank: hasError_bank

                };
            });

            // 에러 상태를 그리드에 반영
            setRowData(updatedRows);

            return !hasErrors; // 에러가 없으면 true 반환
        };

        // 파일 첨부 여부 검증 함수
        const validateFileUpload = () => {
            let fileError = false;
            let businessErrors = []; // 사업자인 경우 에러 메시지
            let nonBusinessErrors = []; // 비사업자인 경우 에러 메시지
            let error = []; // 비사업자인 경우 에러 메시지

            rowData.forEach((row) => {
                const { APP_TYPE, BUSINESS_TYPE, IMG_NM1, IMG_NM2, IMG_NM4 } = row;

                // 파일 첨부 여부 조건
                const isSugiAndJikJungsan =
                    APP_TYPE === "수기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const isKioAndJikJungsan =
                    APP_TYPE === "키오스크" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");

                const danJikJungsan =
                    APP_TYPE === "단말기" &&
                    Array.isArray(payIdCdOptions) &&
                    payIdCdOptions.includes("직정산");
                
                // "수기", "직정산" 조건 추가
                if (isSugiAndJikJungsan   || APP_TYPE === "수기") {
                    if (BUSINESS_TYPE === "1") {
                        // 사업자인 경우
                        if (!IMG_NM1 || IMG_NM1.trim() === "") {
                            fileError = true;
                            businessErrors.push("사업자등록증");
                        }
                        if (!IMG_NM2 || IMG_NM2.trim() === "") {
                            fileError = true;
                            businessErrors.push("상품사진");
                        }
                    }else {
                        // 비사업자인 경우
                        if (!IMG_NM2 || IMG_NM2.trim() === "") {
                            fileError = true;
                            nonBusinessErrors.push("상품사진");
                        }
                    }
                }
                if (isKioAndJikJungsan || danJikJungsan) {
                    if (!IMG_NM4 || IMG_NM4.trim() === "") {
                        fileError = true;
                        error.push("계약서류");
                    }
                }
            });

            // 파일 첨부 오류 시 경고 출력
            if (fileError) {
                if (businessErrors.length > 0) {
                    swal({
                        text: `사업자인 경우 ${businessErrors.join(", ")} 필수입니다.`,
                        icon: "warning",
                        button: "확인",
                    });
                } else if (nonBusinessErrors.length > 0) {
                    swal({
                        text: "비사업자인 경우 상품사진 필수입니다.",
                        icon: "warning",
                        button: "확인",
                    });
                }  else if(error.length > 0 ) {
                    swal({
                        text: "계약서류 필수입니다.",
                        icon: "warning",
                        button: "확인",
                    });
                }
            }

            return !fileError; // 파일 검증 성공 여부 반환
        };

        // 필수값 누락 검증
        if (!validateRowData(rowData)) {
            swal({
                text: "필수 입력값이 누락되었습니다. 입력값을 확인해주세요.",
                icon: "warning",
                button: "확인",
            });
            return; // 요청 중단
        }

        if (!validateFileUpload()) {
            return; // 파일 검증 실패 시 요청 중단
        }

        swal({
            text: "등록 신청하시겠습니까?\n등록 결과 상태는 등록결과에서 확인 가능합니다.",
            buttons: {
                cancel: "아니오",
                confirm: {
                    text: "예",
                    value: true,
                },
            },
            dangerMode: true,
        }).then((willCancel) => {
            if (willCancel) {
                // 데이터를 필터링하지 않고 전체 데이터 전송 준비
                const filteredData2 = rowData
                    .map((row) => ({
                        COL_NM: row.COL_NM,
                        REP_NM: row.REP_NM,
                        HP_NO: row.HP_NO,
                        ADDR_NO: row.ADDR_NO,
                        BS_KIND: row.BS_KIND,
                        CARD_FOR_YN: row.CARD_FOR_YN,
                        APP_TYPE : row.APP_TYPE,
                        APP_PAY : row.APP_PAY,
                        CAT_ID: row.CAT_ID,
                        UID_YN: row.UID_YN,
                        BUSINESS_TYPE: row.BUSINESS_TYPE,
                        CO_NO: row.CO_NO,
                        GID : row.GID,
                        LMT_INSTMN: row.LMT_INSTMN,
                        LMT_SINGLE_AMT: row.LMT_SINGLE_AMT,
                        LMT_MONTH_AMT: row.LMT_MONTH_AMT,
                        VAN_CODE: filteredData[0]?.VAN_CODE,
                        VID: filteredData[0]?.VID || selectedVid,
                        L2_VID: selectedL2Vid,
                        L3_VID: selectedL3Vid,
                        PAYMENT_URL: row.PAYMENT_URL,
                        IMG_NM1: row.IMG_NM1 || fileData["사업자등록증"]?.fileName || "",
                        IMG_NM2: row.IMG_NM2 || fileData["상품사진"]?.fileName || "", // 상품사진 파일명 반영
                        IMG_NM3: row.IMG_NM3 || fileData["기타서류"]?.fileName || "",
                        IMG_NM4: row.IMG_NM4 || fileData["계약서류"]?.fileName || "",
                        IMG_URL: row.IMG_URL || "",
                        N_FEE: row.N_FEE || "",
                        Y_FEE: row.Y_FEE || "",
                        J_FEE1: row.J_FEE1 || "",
                        J_FEE2: row.J_FEE2 || "",
                        J_FEE3: row.J_FEE3 || "",
                        ACT_NO: row.ACT_NO || "",
                        ACT_NM: row.ACT_NM || "",
                        bank: row.bank || "",
                        bankCode: row.bankCode || "",
                        EMAIL: row.EMAIL || "",
                        REGISTER_NUM: row.REGISTER_NUM || "",
                    }));

                // Axios POST 요청으로 전체 데이터를 전송
               axios
                    .post(
                        process.env.REACT_APP_API_HOST + "/mms/requestMid",
                        {
                            token: cookies.token,
                            usrTypeId: cookies.id,
                            usrType: cookies.type,
                            userId: cookies.userid,
                            data: filteredData2, // 모든 행 데이터 배열을 전송
                        }
                    )
                    .then(function (response) {
                        if (response.data.res_code !== "0000") {
                            swal({
                                text: response.data.res_msg,
                                button: { text: "확인" },
                            });
                            if (response.data.res_code === "1004") {
                                swal({
                                    text: response.data.res_msg,
                                    button: { text: "확인" },
                                });
                                navigate("/login", { replace: false });
                            }
                        } else {
                            // 요청이 성공적으로 완료되었을 때
                            swal({
                                text: "등록요청되었습니다.",
                                button: { text: "확인" },
                            }).then(() => {
                                setRowData([]);
                            });
                        }
                    })
                    .catch(function (error) {
                        swal({
                            text: "미지정 오류",
                            button: { text: "확인" },
                        });
                    });
            } else {
                swal({
                    text: "취소되었습니다.",
                    button: { text: "확인" },
                });
            }
        });
    };

  return (
      <>
          <Table  size="small" aria-label="a dense table">
              <TableBody>
                  <TableRow style={{ margin: 0, padding: 0 }}> {/* 전체 Row 간격 조정 */}
                      <TableCell
                          style={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              textAlign: 'left',
                              padding: '4px 8px', // 간격 조정
                              width: '120px',
                          }}
                      >
                          결제 유형
                      </TableCell>
                      <TableCell style={{ padding: '4px 8px', width: '200px' }}>
                          <FormControl sx={{ width: 180 }}>
                              <Select
                                  id="selectVid"
                                  value={selectedVid}
                                  onChange={handleVidChange}
                                  displayEmpty
                                  style={{ height: '50px', fontSize: '16px' }}
                              >
                                  <MenuItem value="">신청 VID를 선택하세요</MenuItem>
                                  {vidOptions.map((option) => (
                                      <MenuItem key={option.L1_VID} value={option.L1_VID}>
                                          {`${option.L1_VID} || ${option.VGRP_NM}`}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </TableCell>
                      <TableCell
                          style={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              textAlign: 'left',
                              padding: '4px 8px',
                              width: '80px',
                          }}
                      >
                          대리점
                      </TableCell>
                      <TableCell style={{ padding: '4px 8px', width: '200px' }}>
                          <FormControl sx={{ width: 180 }}>
                              <Select
                                  value={selectedL2Vid}
                                  onChange={handleL2VidChange}
                                  displayEmpty
                                  disabled={!selectedVid}
                                  style={{ height: '50px', fontSize: '16px' }}
                              >
                                  <MenuItem value="">대리점 선택</MenuItem>
                                  {l2VidOptions.map((option) => (
                                      <MenuItem key={option.L2_VID} value={option.L2_VID}>
                                          {`${option.L2_VID} || ${option.VGRP_NM}`}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </TableCell>
                      <TableCell
                          style={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              textAlign: 'left',
                              padding: '4px 8px',
                              width: '80px',
                          }}
                      >
                          딜러
                      </TableCell>
                      <TableCell style={{ padding: '4px 8px', width: '200px' }}>
                          <FormControl sx={{ width: 180 }}>
                              <Select
                                  value={selectedL3Vid}
                                  onChange={(event) => setSelectedL3Vid(event.target.value)}
                                  displayEmpty
                                  disabled={!selectedL2Vid}
                                  style={{ height: '50px', fontSize: '16px' }}
                              >
                                  <MenuItem value="">딜러 선택</MenuItem>
                                  {l3VidOptions.map((option) => (
                                      <MenuItem key={option.L3_VID} value={option.L3_VID}>
                                          {`${option.L3_VID} || ${option.VGRP_NM}`}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </TableCell>
                  </TableRow>
              </TableBody>
          </Table>
          <br></br>
          <br></br>
          <Card className="table-card">
              <Table sx={{minWidth: 650}} size="small" aria-label="styled table">
                  <TableHead>

                      <TableRow>

                          <TableCell colSpan={3} className="table-section-header">결제 유형</TableCell>


                          <TableCell className="table-section-gap" colSpan={1}></TableCell>


                          <TableCell colSpan={2} className="table-section-header">정산 유형</TableCell>


                          <TableCell className="table-section-gap" colSpan={1}></TableCell>


                          <TableCell colSpan={6} className="table-section-header">가맹점 수수료율</TableCell>
                      </TableRow>


                      <TableRow>
                          <TableCell className="table-header">1차PG</TableCell>
                          <TableCell className="table-header">수기사용여부</TableCell>
                          <TableCell className="table-header">선지급사용여부</TableCell>


                          <TableCell className="table-section-gap"></TableCell>

                          <TableCell className="table-header">정산구분</TableCell>
                          <TableCell className="table-header">정산주기</TableCell>


                          <TableCell className="table-section-gap"></TableCell>

                          <TableCell className="table-header">일반</TableCell>
                          <TableCell className="table-header">중소3</TableCell>
                          <TableCell className="table-header">중소2</TableCell>
                          <TableCell className="table-header">중소1</TableCell>
                          <TableCell className="table-header">영세</TableCell>
                          <TableCell className="table-header">해외카드</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                       {filteredData.map((row, index) => (
                      <TableRow key={index}>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px' }}>{row.PGNM || "-"}</TableCell>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.MANUAL_PAY_FLG || "-"}</TableCell>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.PREPAY_FLG || "-"}</TableCell>


                          <TableCell className="table-section-gap"></TableCell>

                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.PAY_ID_CD || "-"}</TableCell>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.STMT_CYCLE_CD || "-"}</TableCell>


                          <TableCell className="table-section-gap"></TableCell>

                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.N_FEE || "-"} %</TableCell>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.J_FEE3 || "-"} %</TableCell>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.J_FEE2 || "-"} %</TableCell>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.J_FEE1 || "-"} %</TableCell>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.Y_FEE || "-"} %</TableCell>
                          <TableCell className="table-cell" style={{ fontWeight: 'bold', fontSize: '16px'  }}>{row.O_FEE || "-"} %</TableCell>
                      </TableRow>
                           ))}
                  </TableBody>
              </Table>
          </Card>
          <br></br>
          <br></br>
          <Card>
              <Table size="small" aria-label="a dense table">
                  <TableRow>
                      <p style={{fontSize: '12px', fontWeight: 'bold'}}>
                          A.최초 계약 조건에 따라 VID 및 카테고리에 노출되는 항목이 달라집니다.
                      </p>
                      <p style={{fontSize: '12px', fontWeight: 'bold'}}>
                          B.상단 선택하신 VID 유형에 따라 카테고리가 노출됩니다.
                      </p>
                      <p style={{fontSize: '12px', fontWeight: 'bold'}}>
                          C.수기, 영중소의 경우 하단 가맹점 등록시 노출되며, 해당 화면에서 선택 & 입력 가능합니다.
                      </p>
                      <p style={{fontSize: '12px', fontWeight: 'bold', color: 'red'}}>
                          D.모든 수수료는 부가세(VAT)별도만 입력 가능합니다.
                      </p>
                  </TableRow>
              </Table>
          </Card>
          <br></br>
          <Card>
              <CardHeader/>
              <Divider/>
              <div className="ag-theme-alpine" style={{height: 520, width: "100%"}}>
                  <AgGridReact
                      ref={gridRef}
                      rowData={rowData}
                      columnDefs={columnDefs}
                      rowClassRules={{
                          "error-row": (params) => params.data._hasError, // `_hasError`가 true인 경우 클래스 추가
                      }}
                      onGridReady={onGridReady}
                      frameworkComponents={{
                          deleteButtonRenderer: DeleteButtonRenderer, // 삭제 버튼 등록
                      }}
                      defaultColDef={{
                          resizable: true,
                          sortable: true,
                          editable: true, // 글로벌 편집 가능 여부
                          onCellValueChanged: null, // 충돌 방지
                      }}
                      singleClickEdit={true}
                      stopEditingWhenCellsLoseFocus={true}
                  />
              </div>
              <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                  {/* "행 추가" 버튼 중앙 배치 */}
                  <div style={{flex: 1, textAlign: "center"}}>
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={addRow}
                          style={{marginTop: "10px"}}
                      >
                          행 추가
                      </Button>
                  </div>
                  {/* "등록" 버튼 맨 오른쪽 배치 */}
                  <div style={{marginLeft: "auto"}}>
                      <Button
                          variant="contained"
                          style={{
                              marginTop: "10px",
                              backgroundColor: "green",
                              color: "white",
                          }}
                          onClick={requestMid}
                      >
                          등록
                      </Button>
                  </div>
              </div>
          </Card>
          {openModal && (
              <div className="modal-overlay" onClick={handleCloseModal}>
                  <div className="modal" onClick={(e) => e.stopPropagation()}>
                      <h3 style={{ textAlign: "center", marginBottom: "20px" }}>파일 첨부</h3>

                      {(() => {
                          if (payIdCd && (appType === "단말기" || appType === "키오스크")) {
                              // 직정산 단말기/키오스크: 계약서류만 필수
                              return (
                                  <>
                                      <div style={{ marginBottom: "10px" }}>
                                          <label style={{ fontWeight: "bold" }}>* 계약서류:</label>
                                          <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                              <Button
                                                  variant="contained"
                                                  color="success"
                                                  onClick={() => document.getElementById("계약서류").click()}
                                                  style={{ marginRight: "10px" }}
                                              >
                                                  파일 선택
                                              </Button>
                                              <input
                                                  type="file"
                                                  multiple
                                                  id="계약서류"
                                                  style={{ display: "none" }}
                                                  onChange={(e) => handleFileChange(e, "계약서류")}
                                              />
                                              {fileData["계약서류"] && fileData["계약서류"].length > 0 ? (
                                                  <div style={{ marginLeft: "10px", color: "blue" }}>
                                                      {fileData["계약서류"].map((file, index) => (
                                                          <div key={index} style={{ marginBottom: "5px", display: "flex", alignItems: "center" }}>
                                                              <span>{file.fileName}</span>
                                                              <button
                                                                  style={{
                                                                      marginLeft: "10px",
                                                                      background: "red",
                                                                      color: "white",
                                                                      border: "none",
                                                                      cursor: "pointer",
                                                                      padding: "2px 5px",
                                                                  }}
                                                                  onClick={() => handleFileDelete(file.fileName, "계약서류")}
                                                              >
                                                                  X
                                                              </button>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ) : (
                                                  <div style={{ marginLeft: "10px", color: "gray" }}>업로드된 파일 없음</div>
                                              )}
                                          </div>
                                      </div>
                                  </>
                              );
                          }else if(payIdCd && appType === "수기" && businessType === "1") {
                              return (
                                  <>
                                      <div style={{marginBottom: "10px"}}>
                                          <label style={{fontWeight: "bold"}}>* 사업자등록증:</label>
                                          <div style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                                              <Button
                                                  variant="contained"
                                                  color="success"
                                                  onClick={() => document.getElementById("사업자등록증").click()}
                                                  style={{marginRight: "10px"}}
                                              >
                                                  파일 선택
                                              </Button>
                                              <input
                                                  type="file"
                                                  multiple
                                                  id="사업자등록증"
                                                  style={{display: "none"}}
                                                  onChange={(e) => handleFileChange(e, "사업자등록증")}
                                              />
                                              {fileData["사업자등록증"] && fileData["사업자등록증"].length > 0 ? (
                                                  <div style={{marginLeft: "10px", color: "blue"}}>
                                                      {fileData["사업자등록증"].map((file, index) => (
                                                          <div key={index} style={{
                                                              marginBottom: "5px",
                                                              display: "flex",
                                                              alignItems: "center"
                                                          }}>
                                                              <span>{file.fileName}</span>
                                                              <button
                                                                  style={{
                                                                      marginLeft: "10px",
                                                                      background: "red",
                                                                      color: "white",
                                                                      border: "none",
                                                                      cursor: "pointer",
                                                                      padding: "2px 5px",
                                                                  }}
                                                                  onClick={() => handleFileDelete(file.fileName, "사업자등록증")}
                                                              >
                                                                  X
                                                              </button>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ) : (
                                                  <div style={{marginLeft: "10px", color: "gray"}}>업로드된 파일 없음</div>
                                              )}
                                          </div>
                                      </div>

                                      <div style={{marginBottom: "10px"}}>
                                          <label style={{fontWeight: "bold"}}>* 상품사진:</label>
                                          <div style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                                              <Button
                                                  variant="contained"
                                                  color="success"
                                                  onClick={() => document.getElementById("상품사진").click()}
                                                  style={{marginRight: "10px"}}
                                              >
                                                  파일 선택
                                              </Button>
                                              <input
                                                  type="file"
                                                  multiple
                                                  id="상품사진"
                                                  style={{display: "none"}}
                                                  onChange={(e) => handleFileChange(e, "상품사진")}
                                              />
                                              {fileData["상품사진"] && fileData["상품사진"].length > 0 ? (
                                                  <div style={{marginLeft: "10px", color: "blue"}}>
                                                      {fileData["상품사진"].map((file, index) => (
                                                          <div key={index} style={{
                                                              marginBottom: "5px",
                                                              display: "flex",
                                                              alignItems: "center"
                                                          }}>
                                                              <span>{file.fileName}</span>
                                                              <button
                                                                  style={{
                                                                      marginLeft: "10px",
                                                                      background: "red",
                                                                      color: "white",
                                                                      border: "none",
                                                                      cursor: "pointer",
                                                                      padding: "2px 5px",
                                                                  }}
                                                                  onClick={() => handleFileDelete(file.fileName, "상품사진")}
                                                              >
                                                                  X
                                                              </button>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ) : (
                                                  <div style={{marginLeft: "10px", color: "gray"}}>업로드된 파일 없음</div>
                                              )}
                                          </div>
                                      </div>
                                      {<div style={{marginBottom: "10px"}}>
                                          <label style={{fontWeight: "bold"}}>* 계약서류:</label>
                                          <div style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                                              <Button
                                                  variant="contained"
                                                  color="success"
                                                  onClick={() => document.getElementById("계약서류").click()}
                                                  style={{marginRight: "10px"}}
                                              >
                                                  파일 선택
                                              </Button>
                                              <input
                                                  type="file"
                                                  multiple
                                                  id="계약서류"
                                                  style={{display: "none"}}
                                                  onChange={(e) => handleFileChange(e, "계약서류")}
                                              />
                                              {fileData["계약서류"] && fileData["계약서류"].length > 0 ? (
                                                  <div style={{marginLeft: "10px", color: "blue"}}>
                                                      {fileData["계약서류"].map((file, index) => (
                                                          <div key={index} style={{
                                                              marginBottom: "5px",
                                                              display: "flex",
                                                              alignItems: "center"
                                                          }}>
                                                              <span>{file.fileName}</span>
                                                              <button
                                                                  style={{
                                                                      marginLeft: "10px",
                                                                      background: "red",
                                                                      color: "white",
                                                                      border: "none",
                                                                      cursor: "pointer",
                                                                      padding: "2px 5px",
                                                                  }}
                                                                  onClick={() => handleFileDelete(file.fileName, "계약서류")}
                                                              >
                                                                  X
                                                              </button>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ) : (
                                                  <div style={{marginLeft: "10px", color: "gray"}}>업로드된 파일 없음</div>
                                              )}
                                          </div>
                                      </div>}
                                  </>
                              );

                          } else if (businessType === "1") {
                              // 사업자(1)이고 직정산 단말기/키오스크가 아닌 경우: 사업자등록증 + 상품사진 필수
                              return (
                                  <>
                                      <div style={{marginBottom: "10px"}}>
                                          <label style={{fontWeight: "bold"}}>* 사업자등록증:</label>
                                          <div style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                                              <Button
                                                  variant="contained"
                                                  color="success"
                                                  onClick={() => document.getElementById("사업자등록증").click()}
                                                  style={{marginRight: "10px"}}
                                              >
                                                  파일 선택
                                              </Button>
                                              <input
                                                  type="file"
                                                  multiple
                                                  id="사업자등록증"
                                                  style={{display: "none"}}
                                                  onChange={(e) => handleFileChange(e, "사업자등록증")}
                                              />
                                              {fileData["사업자등록증"] && fileData["사업자등록증"].length > 0 ? (
                                                  <div style={{marginLeft: "10px", color: "blue"}}>
                                                      {fileData["사업자등록증"].map((file, index) => (
                                                          <div key={index} style={{
                                                              marginBottom: "5px",
                                                              display: "flex",
                                                              alignItems: "center"
                                                          }}>
                                                              <span>{file.fileName}</span>
                                                              <button
                                                                  style={{
                                                                      marginLeft: "10px",
                                                                      background: "red",
                                                                      color: "white",
                                                                      border: "none",
                                                                      cursor: "pointer",
                                                                      padding: "2px 5px",
                                                                  }}
                                                                  onClick={() => handleFileDelete(file.fileName, "사업자등록증")}
                                                              >
                                                                  X
                                                              </button>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ) : (
                                                  <div style={{marginLeft: "10px", color: "gray"}}>업로드된 파일 없음</div>
                                              )}
                                          </div>
                                      </div>

                                      <div style={{marginBottom: "10px"}}>
                                          <label style={{fontWeight: "bold"}}>* 상품사진:</label>
                                          <div style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                                              <Button
                                                  variant="contained"
                                                  color="success"
                                                  onClick={() => document.getElementById("상품사진").click()}
                                                  style={{marginRight: "10px"}}
                                              >
                                                  파일 선택
                                              </Button>
                                              <input
                                                  type="file"
                                                  multiple
                                                  id="상품사진"
                                                  style={{display: "none"}}
                                                  onChange={(e) => handleFileChange(e, "상품사진")}
                                              />
                                              {fileData["상품사진"] && fileData["상품사진"].length > 0 ? (
                                                  <div style={{marginLeft: "10px", color: "blue"}}>
                                                      {fileData["상품사진"].map((file, index) => (
                                                          <div key={index} style={{
                                                              marginBottom: "5px",
                                                              display: "flex",
                                                              alignItems: "center"
                                                          }}>
                                                              <span>{file.fileName}</span>
                                                              <button
                                                                  style={{
                                                                      marginLeft: "10px",
                                                                      background: "red",
                                                                      color: "white",
                                                                      border: "none",
                                                                      cursor: "pointer",
                                                                      padding: "2px 5px",
                                                                  }}
                                                                  onClick={() => handleFileDelete(file.fileName, "상품사진")}
                                                              >
                                                                  X
                                                              </button>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ) : (
                                                  <div style={{marginLeft: "10px", color: "gray"}}>업로드된 파일 없음</div>
                                              )}
                                          </div>
                                      </div>
                                      {/*<div style={{marginBottom: "10px"}}>
                                          <label style={{fontWeight: "bold"}}>* 계약서류:</label>
                                          <div style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                                              <Button
                                                  variant="contained"
                                                  color="success"
                                                  onClick={() => document.getElementById("계약서류").click()}
                                                  style={{marginRight: "10px"}}
                                              >
                                                  파일 선택
                                              </Button>
                                              <input
                                                  type="file"
                                                  multiple
                                                  id="계약서류"
                                                  style={{display: "none"}}
                                                  onChange={(e) => handleFileChange(e, "계약서류")}
                                              />
                                              {fileData["계약서류"] && fileData["계약서류"].length > 0 ? (
                                                  <div style={{marginLeft: "10px", color: "blue"}}>
                                                      {fileData["계약서류"].map((file, index) => (
                                                          <div key={index} style={{
                                                              marginBottom: "5px",
                                                              display: "flex",
                                                              alignItems: "center"
                                                          }}>
                                                              <span>{file.fileName}</span>
                                                              <button
                                                                  style={{
                                                                      marginLeft: "10px",
                                                                      background: "red",
                                                                      color: "white",
                                                                      border: "none",
                                                                      cursor: "pointer",
                                                                      padding: "2px 5px",
                                                                  }}
                                                                  onClick={() => handleFileDelete(file.fileName, "계약서류")}
                                                              >
                                                                  X
                                                              </button>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ) : (
                                                  <div style={{marginLeft: "10px", color: "gray"}}>업로드된 파일 없음</div>
                                              )}
                                          </div>
                                      </div>*/}
                                  </>
                              );
                          } else if (businessType === "2") {
                              // 비사업자(2)이고 직정산 단말기/키오스크가 아닌 경우: 상품사진만 필수
                             return (
                                  <>
                                      <div style={{ marginBottom: "10px" }}>
                                          <label style={{ fontWeight: "bold" }}>* 상품사진:</label>
                                          <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                              <Button
                                                  variant="contained"
                                                  color="success"
                                                  onClick={() => document.getElementById("상품사진").click()}
                                                  style={{ marginRight: "10px" }}
                                              >
                                                  파일 선택
                                              </Button>
                                              <input
                                                  type="file"
                                                  multiple
                                                  id="상품사진"
                                                  style={{ display: "none" }}
                                                  onChange={(e) => handleFileChange(e, "상품사진")}
                                              />
                                              {fileData["상품사진"] && fileData["상품사진"].length > 0 ? (
                                                  <div style={{ marginLeft: "10px", color: "blue" }}>
                                                      {fileData["상품사진"].map((file, index) => (
                                                          <div key={index} style={{
                                                              marginBottom: "5px",
                                                              display: "flex",
                                                              alignItems: "center"
                                                          }}>
                                                              <span>{file.fileName}</span>
                                                              <button
                                                                  style={{
                                                                      marginLeft: "10px",
                                                                      background: "red",
                                                                      color: "white",
                                                                      border: "none",
                                                                      cursor: "pointer",
                                                                      padding: "2px 5px",
                                                                  }}
                                                                  onClick={() => handleFileDelete(file.fileName, "상품사진")}
                                                              >
                                                                  X
                                                              </button>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ) : (
                                                  <div style={{ marginLeft: "10px", color: "gray" }}>업로드된 파일 없음</div>
                                              )}
                                          </div>
                                      </div>

                                      {!payIdCdOptions.includes("재정산") && (
                                          <div style={{ marginBottom: "10px" }}>
                                              <label style={{ fontWeight: "bold" }}>* 계약서류:</label>
                                              <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                                  <Button
                                                      variant="contained"
                                                      color="success"
                                                      onClick={() => document.getElementById("계약서류").click()}
                                                      style={{ marginRight: "10px" }}
                                                  >
                                                      파일 선택
                                                  </Button>
                                                  <input
                                                      type="file"
                                                      multiple
                                                      id="계약서류"
                                                      style={{ display: "none" }}
                                                      onChange={(e) => handleFileChange(e, "계약서류")}
                                                  />
                                                  {fileData["계약서류"] && fileData["계약서류"].length > 0 ? (
                                                      <div style={{ marginLeft: "10px", color: "blue" }}>
                                                          {fileData["계약서류"].map((file, index) => (
                                                              <div
                                                                  key={index}
                                                                  style={{
                                                                      marginBottom: "5px",
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                  }}
                                                              >
                                                                  <span>{file.fileName}</span>
                                                                  <button
                                                                      style={{
                                                                          marginLeft: "10px",
                                                                          background: "red",
                                                                          color: "white",
                                                                          border: "none",
                                                                          cursor: "pointer",
                                                                          padding: "2px 5px",
                                                                      }}
                                                                      onClick={() =>
                                                                          handleFileDelete(file.fileName, "계약서류")
                                                                      }
                                                                  >
                                                                      X
                                                                  </button>
                                                              </div>
                                                          ))}
                                                      </div>
                                                  ) : (
                                                      <div style={{ marginLeft: "10px", color: "gray" }}>
                                                          업로드된 파일 없음
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                      )}
                                  </>
                              );
                          }
                      })()}

                      {/* 항상 표시되는 기타서류 */}
                      <div style={{ marginBottom: "10px" }}>
                          <label>기타서류:</label>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                              <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => document.getElementById("기타서류").click()}
                                  style={{ marginRight: "10px" }}
                              >
                                  파일 선택
                              </Button>
                              <input
                                  type="file"
                                  multiple
                                  id="기타서류"
                                  style={{ display: "none" }}
                                  onChange={(e) => handleFileChange(e, "기타서류")}
                              />
                              {fileData["기타서류"] && fileData["기타서류"].length > 0 ? (
                                  <div style={{ marginLeft: "10px", color: "blue" }}>
                                      {fileData["기타서류"].map((file, index) => (
                                          <div key={index} style={{ marginBottom: "5px", display: "flex", alignItems: "center" }}>
                                              <span>{file.fileName}</span>
                                              <button
                                                  style={{
                                                      marginLeft: "10px",
                                                      background: "red",
                                                      color: "white",
                                                      border: "none",
                                                      cursor: "pointer",
                                                      padding: "2px 5px",
                                                  }}
                                                  onClick={() => handleFileDelete(file.fileName, "기타서류")}
                                              >
                                                  X
                                              </button>
                                          </div>
                                      ))}
                                  </div>
                              ) : (
                                  <div style={{ marginLeft: "10px", color: "gray" }}>업로드된 파일 없음</div>
                              )}
                          </div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                          <Button variant="outlined" color="primary" onClick={handleCloseModal}>
                              닫기
                          </Button>
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                  if (validateFiles()) {
                                      handleFileUpload();
                                  }
                              }}
                          >
                              파일 등록
                          </Button>
                      </div>
                  </div>
              </div>
          )}



      </>
  );
};

export default ApplyMid;