import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation ,useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  CreditCard as CreditCardIcon,
  MessageSquare as MessageSquareIcon,
  LogOut as LogOutIcon,
  Send as SendIcon,
  Search as serarchIcon
} from 'react-feather';
import NavItem from './NavItem';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import './NavBar.css';


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
    
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));


const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [items,setItems]= useState([]);
  const [active, setActive] = useState(null);
  const [target, setTarget] = useState('');

  var arr =[];

  if (isMobile){
      arr.push({href: '/app/trans',icon: BarChartIcon,title: '거래내역'});
      if(cookies.manualpay == 1){
      arr.push({ href: '/app/manualpaylist',icon: CreditCardIcon,title: '수기결제'});
     }
     if(cookies.sms == 1){
       arr.push({href: '/app/smspaylist',icon: MessageSquareIcon,title: 'SMS결제'});
      }
    arr.push({href: '/login',icon: LogOutIcon,title: '로그아웃'});
   }else{
    arr.push({href: '/app/midinfo',icon: SettingsIcon,title: '가맹점 정보'});
    if(cookies.type === "5" || cookies.type === "9") {
        arr.push({
            href: '/app/applymid',
            icon: AlertCircleIcon,
            title: 'MID등록신청',
            subMenu: [
                {href: '/app/applymid', title: '등록신청', icon: ''},
                { href: '/app/applymidresult', title: '등록결과', icon: '' },
            ]
        });
    }

    arr.push({href: '/app/trans',icon: BarChartIcon,title: '거래내역'});
    arr.push({href: '/app/cancel',icon: BarChartIcon,title: '거래취소 요청내역'});
    arr.push({href: '/app/bankreport',icon: ShoppingBagIcon,title: '입금보고서'});
    if(cookies.type >4){
      arr.push({ href: '/app/agencyreport',icon: CreditCardIcon,title: '영업대행사'});
    }
    //arr.push({href: '/app/surtax',icon: serarchIcon,title: '부가세신고 조회'});
    //arr.push({href: '/app/midfee',icon: ShoppingBagIcon,title: '가맹점수수료'});
    arr.push({href: '/app/notiresend',icon: SendIcon,title: '결과통보조회'});
    if(cookies.manualpay == 1){
      arr.push({ href: '/app/manualpay',icon: CreditCardIcon,title: '수기결제'});
     }
     if(cookies.sms == 1){
       arr.push({href: '/app/smspaylist',icon: MessageSquareIcon,title: 'SMS결제'});
      }
    arr.push({href: '/app/msmbssearch',icon: serarchIcon,title: '우대사업자 조회'});
      arr.push({
          href: '/app/notice?search=noticeList',
          icon: AlertCircleIcon,
          title: '고객지원',
          subMenu: [
              { href: '/app/notice?search=noticeList', title: '공지사항', icon: '' },
              { href: '/app/notice?search=faqList', title: 'FAQ', icon: '' },
          ]
      });
    arr.push({href: '#',icon: LogOutIcon,title: '비밀번호 변경' ,id:'change'});
    arr.push({href: '/login',icon: LogOutIcon,title: '로그아웃'});
   }
   
   function leftBar(){
    setItems(arr);
  }

  function menuChange(item){
    setActive(item)
  }

  function isActiveSub(target){
    setTarget(target);
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    leftBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <Box
    onClick={onMobileClose}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <div 
            className={`list-group-item ${active == item && item.subMenu == null  && 'active'}`}
            id = {item.id}  
             key={item.title}
              onClick={()=> {item.id=='change'? navigate('/password', {
              state: {
                uid: cookies.userid
              },
            }):menuChange(item);
              isActiveSub(item.title);
              }}>
            <NavItem 
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              subMenu={item.subMenu}
              target={target}
            />
            {active === item && item.subMenu && (
                <List>
                    {item.subMenu.map((subItem) => (
                        <div
                            key={subItem.title}
                            className={`list-group-item ${location.pathname+location.search === subItem.href && 'active'}`}
                            onClick={() => {
                                navigate(subItem.href);
                            }}
                        >
                            <NavItem
                                href={subItem.href}
                                key={subItem.title}
                                title={subItem.title}
                                icon={subItem.icon}
                            />
                        </div>
                    ))}
                </List>
            )}
            </div>
          ))}
        </List>
      </Box>
      {/* <Box flexGrow={1} /> */}
      {/* <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box>
      </Box> */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;