import React, { useState, useContext, useEffect, useRef  } from 'react';
import {
    Card,
    Divider,
    CardHeader, colors
} from '@material-ui/core';
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material';
import GroupVidVidGidMidInfo from '../../../common/GroupVidVidGidMidInfo';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl } from '@material-ui/core';
import { getFormatDate } from '../../../common/getFormatDate';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { deleteSpecialChar } from '../../../common/deleteSpecialChar';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router-dom';
import ko from "date-fns/locale/ko";
import {dateRange} from "../../../common/dateRange";



const ApplyMidSl = () => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [vidOptions, setVidOptions] = useState([]); // 결제 유형
    const [l2VidOptions, setL2VidOptions] = useState([]); // 대리점
    const [l3VidOptions, setL3VidOptions] = useState([]); // 딜러
    const [selectedVid, setSelectedVid] = useState(""); // 선택된 결제 유형
    const [selectedL2Vid, setSelectedL2Vid] = useState(""); // 선택된 대리점
    const [selectedL3Vid, setSelectedL3Vid] = useState(""); // 선택된 딜러
    const [filteredData, setFilteredData] = useState([]); //
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const dateList = [{ value: 'regdt', lavel: '신청일자' }];
    const gridRef = useRef(null); // 그리드 API에 접근하기 위한 참조 생성
    const [rowData, setRowData] = useState([]); // 데이터 상태
    const [attachmentFiles, setAttachmentFiles] = useState([]); // 첨부파일 데이터 상태
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태
    const [selectedReqNo, setSelectedReqNo] = useState(null); // 선택된 REQ_NO
    const [dateType, setDateType] = useState('regdt');
    const [selectType, setSelectType] = useState('select');
    const selectTypeList = [
        { value: 'select', label: '선택하세요' },
        { value: 'cono', label: '사업자번호' },
        { value: 'gid', label: 'GID' },
        { value: 'vid', label: '총판' },
        { value: 'repnm', label: '상점명' }
    ];
    const [structuredAttachmentFiles, setStructuredAttachmentFiles] = useState({
        NM1: [],
        NM2: [],
        NM3: [],
        NM4: []
    });

    const [selectVidType, setSelectVidType] = useState('select');
    const selectVidTypeList = [
        { value: 'select', label: '선택하세요' },
        { value: '1', label: '가맹점심사대기' },
        { value: '2', label: '수기심사대기' },
        { value: '3', label: '등록완료' },
        { value: '4', label: '반려' }
        ];

    const [selectVidSign, setSelectVidSign] = useState('select');
    const selectVidSignYn = [
        { value: 'select', label: '가맹점서명여부' },
        { value: 'Y', label: '서명완료' },
        { value: 'N', label: '서명미완료' },
    ];

    const currentDate = new Date();
    const [startDate, setStartDate] = useState(getFormatDate(currentDate));
    const [endDate, setEndDate] = useState(getFormatDate(currentDate));

    const handleAttachmentView = (params) => {
        setSelectedReqNo(params.data.REQ_NO); // 선택된 REQ_NO 저장
        fetchAttachmentFiles(params.data.REQ_NO); // 첨부파일 데이터 조회
        setIsModalOpen(true); // 모달 열기
    };

    // 모달 닫기
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setAttachmentFiles([]);
        setSelectedReqNo(null);
    };

    const handleViewFile = (fileName) => {
        Swal.showLoading();

        // 파일 이름이 콤마로 연결된 경우 분리
        const fileNames = fileName.split(",").map((name) => name.trim());

        // 각 파일 처리
        fileNames.forEach((file) => {
            axios
                .get(`${process.env.REACT_APP_API_HOST}/contfile/read`, {
                    params: {
                        type: "view",
                        file: file,
                    },
                })
                .then((response) => {
                    if (response.data.url) {
                        // 새 창 크기 및 위치 설정
                        const windowFeatures =
                            "width=800,height=600,scrollbars=yes,resizable=yes,top=100,left=100";
                        window.open(response.data.url, "_blank", windowFeatures);
                    } else {
                        Swal.fire("Error", "파일 URL을 가져오는 데 실패했습니다.", "error");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching file URL:", error);
                    Swal.fire("Error", "파일을 가져오는 중 오류가 발생했습니다.", "error");
                })
                .finally(() => {
                    Swal.close();
                });
        });
    };



    const dateButton = (e) => {
        const date = new Date();
        const dates = new Date();
        if (e.target.value == '0') {
            date.setDate(date.getDate() - 1);
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(date));
        } else if (e.target.value == '1') {
            date.setDate(date.getDate());
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(date));
        } else if (e.target.value == '2') {
            date.setDate(date.getDate() - 7);
            dates.setDate(dates.getDate());
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(dates));
        } else if (e.target.value == '3') {
            date.setDate(date.getDate() - 31);
            dates.setDate(dates.getDate());
            setStartDate(getFormatDate(date));
            setEndDate(getFormatDate(dates));
        }
    }

    const fetchAttachmentFiles = (reqNo) => {
        Swal.showLoading();
        axios
            .post(
                `${process.env.REACT_APP_API_HOST}/mms/selectFileList`,
                {
                    reqNo: reqNo,
                    token: cookies.token,
                    userId: cookies.userid,
                    usrTypeId: cookies.id,
                    usrType: cookies.type,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                    },
                }
            )
            .then((response) => {
                if (response.data.res_code !== "0000") {
                    // 오류 메시지 표시
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: "확인",
                        },
                    });

                    // 로그인 세션 만료 처리
                    if (response.data.res_code === "1004") {
                        navigate("/login", { replace: false });
                    }
                } else {
                    const structuredFiles = {
                        NM1: [],
                        NM2: [],
                        NM3: [],
                        NM4: [],
                    };

                    response.data.data.forEach((file) => {
                        if (file.IMG_NM1) structuredFiles.NM1.push(file.IMG_NM1);
                        if (file.IMG_NM2) structuredFiles.NM2.push(file.IMG_NM2);
                        if (file.IMG_NM3) structuredFiles.NM3.push(file.IMG_NM3);
                        if (file.IMG_NM4) structuredFiles.NM4.push(file.IMG_NM4);
                    });

                    setStructuredAttachmentFiles(structuredFiles); // 상태 업데이트
                    console.log("첨부파일 IMG_NM 리스트:", structuredFiles);
                    Swal.close();
                }
            })
            .catch((error) => {
                // 네트워크 또는 서버 오류 처리
                swal({
                    text: "첨부파일 조회 중 오류가 발생했습니다.",
                    button: {
                        text: "확인",
                    },
                });
                console.error("Error fetching attachment files:", error);
            })
            .finally(() => {
                Swal.close();
            });
    };




    const [selectedRows, setSelectedRows] = useState([]);



    const columnDefs = [
        { headerName: "REQ_NO", field: "REQ_NO", width: 120, hide: true },
        { headerCheckboxSelection: true, checkboxSelection: true, width: 50 },
        { headerName: "상태", field: "MID_STATUS", width: 150 },
        { headerName: "비고", field: "REMARK", width: 200 },
        { headerName: "상호", field: "COL_NM", width: 120 },
        { headerName: "CAT_ID", field: "CAT_ID", width: 150 },
        { headerName: "VID", field: "VID", width: 150 },
        { headerName: "대리점", field: "L2_VID", width: 150 },
        { headerName: "딜러", field: "L3_VID", width: 150 },
        { headerName: "PG사", field: "PGNM", width: 140 },
        { headerName: "정산구분", field: "PAY_ID_CD", width: 100 },
        { headerName: "정산주기", field: "STMT_CYCLE_CD", width: 100 },
        { headerName: "결제유형", field: "APP_TYPE", width: 140 },
        { headerName: "CPID", field: "APP_PAY", width: 140 },
        { headerName: "GID", field: "GID", width: 130 },
        { headerName: "대표자명", field: "REP_NM", width: 100 },
        { headerName: "연락처", field: "HP_NO", width: 120 },
        { headerName: "주소", field: "ADDR_NO", width: 150 },
        { headerName: "업종(결제상품명)", field: "BS_KIND", width: 150 },
        { headerName: "가맹점 아이디 발급여부", field: "UID_YN", width: 180 },
        { headerName: "사업자번호여부", field: "BUSINESS_TYPE", width: 180 },
        { headerName: "사업자번호", field: "CO_NO", width: 180 },
        { headerName: "주민등록번호", field: "REGISTER_NUM", width: 180 },
        { headerName: "이메일", field: "EMAIL", width: 150 },
        { headerName: "은행", field: "BANK_CD", width: 150 },
        { headerName: "입금계좌번호", field: "ACNT_NO", width: 150 },
        { headerName: "예금주", field: "ACNT_NM", width: 150 },
        { headerName: "일반 수수료", field: "N_FEE", width: 150 },
        { headerName: "영중소계약여부", field: "MS_USE_FLG",hide: true, width: 150 },
        { headerName: "중소3 수수료", field: "J_FEE3", width: 120 },
        { headerName: "중소2 수수료", field: "J_FEE2", width: 120 },
        { headerName: "중소1 수수료", field: "J_FEE1", width: 120 },
        { headerName: "영세 수수료", field: "Y_FEE", width: 120 },
        { headerName: "선지급 사용여부", field: "PREPAY_FLG", width: 100 },
        { headerName: "해외 사용여부", field: "CARD_FOR_YN", width: 100 },
        { headerName: "해외카드 수수료", field: "O_FEE", width: 120 },
        { headerName: "수기 사용여부", field: "MANUAL_PAY_FLG", width: 120 },
        { headerName: "1회 결제한도", field: "LMT_SINGLE_AMT", width: 120 },
        { headerName: "월 결제한도", field: "LMT_MONTH_AMT", width: 120 },
        { headerName: "보증보험증권 신청여부", field: "INSURANCE_FLG", hide: true, width: 100 },
        { headerName: "현금영수증 사용여부", field: "VAT_MARK_FLG", hide: true, width: 100 },
        {
            headerName: "첨부파일 보기",
            field: "FILE_YN",
            cellRendererFramework: (params) => {
                if (params.data.FILE_YN === "Y") {
                    return (
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => handleAttachmentView(params)}
                        >
                            보기
                        </Button>
                    );
                } else {
                    return null; // FILE_YN이 'Y'가 아니면 버튼 숨김
                }
            },
            width: 120,
        },
        { headerName: "신청일시", field: "REG_DNT",  width: 180 },
        { headerName: "상태변경일시", field: "UPD_DNT", width: 100 },
    ];

    const [columnState, setColumnState] = useState(null);

    useEffect(() => {
        // 컴포넌트 마운트 시 컬럼 상태 로드
        const savedState = JSON.parse(localStorage.getItem("columnState"));
        if (savedState) {
            setColumnState(savedState);
        }
    }, []);

    // 컬럼 상태가 변경될 때 로컬 스토리지에 저장
    useEffect(() => {
        if (columnState) {
            localStorage.setItem("columnState", JSON.stringify(columnState));
        }
    }, [columnState]);



    //그리드 사이즈
  const rowHeight = 40;
  //거래 리스트 data

  //거래 리스트 total
  const [rowDataTotal, setRowDataTotal] = useState({});

    const selectMidList = () => {

        Swal.showLoading();
        axios.
        post(process.env.REACT_APP_API_HOST + "/mms/selectMidList"
            , {
                token: cookies.token,
                userId : cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
                startDate: deleteSpecialChar(startDate),
                endDate: deleteSpecialChar(endDate),
                dateType: dateType,
                selectVidType: selectVidType,
                selectedVid: selectedVid,
                selectedL2Vid: selectedL2Vid,
                selectedL3Vid: selectedL3Vid,
                selectType : selectType,
                textSelectType: document.getElementById('textSelectType').value,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }
        ).then(function (response) {
            if (response.data.res_code != '0000') {
                swal({
                    text: response.data.res_msg,
                    button: {
                        text: '확인',
                    },
                });
                if (response.data.res_code == '1004') {
                    swal({
                        text: response.data.res_msg,
                        button: {
                            text: '확인',
                        },
                    });
                    navigate('/login', { replace: false });
                }
                Swal.close();
            } else {
                setRowData(response.data.data);
                setRowDataTotal(response.data.total);
                Swal.close();
            }
        }).catch(function (error) {
            Swal.close();
            swal({
                text: '미지정 오류',
                button: {
                    text: '확인',
                },
            });
        });
    }



  //결제유형 VID SELECT박스 조회
  const fetchVidOptions  = () => {
    Swal.showLoading();
    axios.
      post(process.env.REACT_APP_API_HOST + "/mms/selectVidList"
        , {
          token: cookies.token,
          userId : cookies.userid,
          usrTypeId: cookies.id,
          usrType: cookies.type,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      ).then(function (response) {
        if (response.data.res_code != '0000') {
          swal({
            text: response.data.res_msg,
            button: {
              text: '확인',
            },
          });
          if (response.data.res_code == '1004') {
            swal({
              text: response.data.res_msg,
              button: {
                text: '확인',
              },
            });
            navigate('/login', { replace: false });
          }
          Swal.close();
        } else {
          setVidOptions(response.data.data);
          Swal.close();
        }
      }).catch(function (error) {
        Swal.close();
        swal({
          text: '미지정 오류',
          button: {
            text: '확인',
          },
        });
      });
  }

    useEffect(() => {
        fetchVidOptions();
    }, []);


    const selectVgrp = () => {
        Swal.showLoading();
        axios
            .post(process.env.REACT_APP_API_HOST + "/mms/selectVgrpList", {
                token: cookies.token,
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
                vid:vidOptions[0].L1_VID
            })
            .then((response) => {
                if (response.data.res_code !== "0000") {
                    swal({
                        text: response.data.res_msg,
                        button: { text: "확인" },
                    });
                } else {
                    console.log("Filtered Data:", response.data.data); // 데이터 출력
                    // 필요한 상태에 데이터 저장
                    setFilteredData(response.data.data);
                }
                Swal.close();
            })
            .catch((error) => {
                Swal.close();
                swal({ text: "조회 중 오류가 발생했습니다.", button: { text: "확인" } });
                console.error(error);
            });
    };

    const fetchL2VidOptions = (l1Vid) => {
        Swal.showLoading();
        axios
            .post(process.env.REACT_APP_API_HOST + "/mms/selectl2vid", {
                l1Vid,
                token: cookies.token,
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
            })
            .then((response) => {
                if (response.data.res_code !== "0000") {
                    Swal.fire("Error", response.data.res_msg, "error");
                } else {
                    setL2VidOptions(response.data.data);
                }
                Swal.close();
            })
            .catch(() => {
                Swal.close();
                Swal.fire("Error", "대리점 조회 중 오류가 발생했습니다.", "error");
            });
    };

    // 딜러 조회
    const fetchL3VidOptions = (l1Vid, l2Vid) => {
        Swal.showLoading();
        axios
            .post(process.env.REACT_APP_API_HOST + "/mms/selectl3vid", {
                l1Vid,
                l2Vid,
                token: cookies.token,
                userId: cookies.userid,
                usrTypeId: cookies.id,
                usrType: cookies.type,
            })
            .then((response) => {
                if (response.data.res_code !== "0000") {
                    Swal.fire("Error", response.data.res_msg, "error");
                } else {
                    setL3VidOptions(response.data.data);
                }
                Swal.close();
            })
            .catch(() => {
                Swal.close();
                Swal.fire("Error", "딜러 조회 중 오류가 발생했습니다.", "error");
            });
    };

    useEffect(() => {
        fetchVidOptions();
    }, []);

    // 결제 유형 선택 핸들러
    const handleVidChange = (event) => {
        const value = event.target.value;
        setSelectedVid(value);
        setSelectedL2Vid("");
        setSelectedL3Vid("");
        setL2VidOptions([]);
        setL3VidOptions([]);
        if (value) {
            fetchL2VidOptions(value);
            selectVgrp(value);
        }
    };

    // 대리점 선택 핸들러
    const handleL2VidChange = (event) => {
        const value = event.target.value;
        setSelectedL2Vid(value);
        setSelectedL3Vid("");
        setL3VidOptions([]);
        if (value && selectedVid) {
            fetchL3VidOptions(selectedVid, value);
        }
    };



  return (
      <>
          <div style={{float: 'right'}}>
              <Button size="small" variant="contained" color="primary" onClick={selectMidList} >
                  조회
              </Button>
             {/* <Button size="small" variant="contained" sx={{ backgroundColor: 'purple', color: 'white', '&:hover': { backgroundColor: '#6a0dad' } }}>
                  재접수
              </Button>*/}
          </div>
          <Table size="small" aria-label="a dense table">
              <TableBody>
                  <TableRow style={{margin: 0, padding: 0}}> {/* 전체 Row 간격 조정 */}
                      <TableCell
                          style={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              textAlign: 'left',
                              padding: '4px 8px', // 간격 조정
                              width: '120px',
                          }}
                      >
                          결제 유형
                      </TableCell>
                      <TableCell style={{padding: '4px 8px', width: '200px'}}>
                          <FormControl sx={{width: 180}}>
                              <Select
                                  id="selectVid"
                                  value={selectedVid}
                                  onChange={handleVidChange}
                                  displayEmpty
                                  style={{height: '50px', fontSize: '16px'}}
                              >
                                  <MenuItem value="">신청 VID를 선택하세요</MenuItem>
                                  {vidOptions.map((option) => (
                                      <MenuItem key={option.L1_VID} value={option.L1_VID}>
                                          {`${option.L1_VID} || ${option.VGRP_NM}`}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </TableCell>
                      <TableCell
                          style={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              textAlign: 'left',
                              padding: '4px 8px',
                              width: '80px',
                          }}
                      >
                          대리점
                      </TableCell>
                      <TableCell style={{padding: '4px 8px', width: '200px'}}>
                          <FormControl sx={{width: 180}}>
                              <Select
                                  value={selectedL2Vid}
                                  onChange={handleL2VidChange}
                                  displayEmpty
                                  style={{height: '50px', fontSize: '16px'}}
                              >
                                  <MenuItem value="">대리점 선택</MenuItem>
                                  {l2VidOptions.map((option) => (
                                      <MenuItem key={option.L2_VID} value={option.L2_VID}>
                                          {`${option.L2_VID} || ${option.VGRP_NM}`}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </TableCell>
                      <TableCell
                          style={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              textAlign: 'left',
                              padding: '4px 8px',
                              width: '80px',
                          }}
                      >
                          딜러
                      </TableCell>
                      <TableCell style={{padding: '4px 8px', width: '200px'}}>
                          <FormControl sx={{width: 180}}>
                              <Select
                                  value={selectedL3Vid}
                                  onChange={(event) => setSelectedL3Vid(event.target.value)}
                                  displayEmpty
                                  style={{height: '50px', fontSize: '16px'}}
                              >
                                  <MenuItem value="">딜러 선택</MenuItem>
                                  {l3VidOptions.map((option) => (
                                      <MenuItem key={option.L3_VID} value={option.L3_VID}>
                                          {`${option.L3_VID} || ${option.VGRP_NM}`}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </TableCell>
                  </TableRow>
              </TableBody>
          </Table>
          <br></br>
          <Card style={{padding: '20px', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                  <FormControl sx={{width: 200}}>
                      <Select
                          size="small"
                          defaultValue={'select'}
                          value={selectType}
                          onChange={(event) => setSelectType(event.target.value)}
                      >
                          {selectTypeList.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>

                  <TextField
                      id="textSelectType"
                      size="small"
                      variant="outlined"
                      placeholder="검색"
                      style={{width: '200px'}}
                  />
              </div>

              <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px', gap: '10px'}}>
                  <FormControl sx={{width: 200}}>
                      <Select size='small'
                          //  maxDate={currentDate}
                              defaultValue={'regdt'}
                              value={dateList.value}
                              inputProps={{
                                  id: 'selectDateType',
                              }}
                              onChange={(event) => {
                                  setDateType(event.target.value);
                              }}
                      >
                          {dateList?.map(option => {
                              return (
                                  <MenuItem key={option} value={option.value}>{option.lavel}</MenuItem>
                              );
                          })}
                      </Select>
                  </FormControl>

                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                      <DatePicker
                          value={startDate}
                          inputFormat={"yyyy/MM/dd"}
                          mask={"____/__/__"}
                          onChange={(newValue) => setStartDate(getFormatDate(newValue))}
                          renderInput={(params) => <TextField {...params} size="small" style={{width: '150px'}}/>}
                      />
                  </LocalizationProvider>
                  <span>~</span>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                      <DatePicker
                          value={endDate}
                          inputFormat={"yyyy/MM/dd"}
                          mask={"____/__/__"}
                          onChange={(newValue) => setEndDate(getFormatDate(newValue))}
                          renderInput={(params) => <TextField {...params} size="small" style={{width: '150px'}}/>}
                      />
                  </LocalizationProvider>

                  {/* 버튼 그룹 */}
                  <div style={{display: 'flex', gap: '5px'}}>
                      <Button size="medium" variant="outlined" color="primary" onClick={dateButton} value="0">
                          어제
                      </Button>
                      <Button size="medium" variant="outlined" color="primary" onClick={dateButton} value="1">
                          오늘
                      </Button>
                      <Button size="medium" variant="outlined" color="primary" onClick={dateButton} value="2">
                          일주일
                      </Button>
                      <Button size="medium" variant="outlined" color="primary" onClick={dateButton} value="3">
                          1개월
                      </Button>
                  </div>
              </div>
          </Card>
          <br></br>
          <Card style={{padding: '20px', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                  <FormControl sx={{width: 200}}>
                      <Select
                          size="small"
                          value={selectVidType || ''}
                          onChange={(event) => setSelectVidType(event.target.value)}
                      >
                          {selectVidTypeList.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px', gap: '10px'}}>
                  <FormControl sx={{width: 200}}>
                      <Select
                          size="small"
                          defaultValue={'select'}
                          value={selectVidSign}
                          onChange={(event) => setSelectVidSign(event.target.value)}
                      >
                          {selectVidSignYn.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </div>
          </Card>
          <Modal open={isModalOpen} onClose={handleCloseModal}>
              <div
                  style={{
                      padding: "20px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      maxWidth: "500px",
                      margin: "50px auto",
                  }}
              >
                  <h2>첨부서류</h2>
                  {Object.values(structuredAttachmentFiles).some((files) => files.length > 0) ? (
                      <>
                          {/* NM1: 사업자등록증 */}
                          {structuredAttachmentFiles.NM1.length > 0 && (
                              <>
                                  <h3>사업자등록증</h3>
                                  <ul style={{ listStyleType: "none", padding: 0 }}>
                                      {structuredAttachmentFiles.NM1.map((fileName, index) => (
                                          fileName.split(",").map((name, subIndex) => (
                                              <li key={`${index}-${subIndex}`} style={{ margin: "8px 0" }}>
                                                  <a
                                                      href="#!"
                                                      onClick={() => handleViewFile(name.trim())}
                                                      style={{
                                                          color: "blue",
                                                          textDecoration: "underline",
                                                          cursor: "pointer",
                                                      }}
                                                  >
                                                      {name.trim()}
                                                  </a>
                                              </li>
                                          ))
                                      ))}
                                  </ul>
                              </>
                          )}

                          {/* NM2: 상품사진 */}
                          {structuredAttachmentFiles.NM2.length > 0 && (
                              <>
                                  <h3>상품사진</h3>
                                  <ul style={{ listStyleType: "none", padding: 0 }}>
                                      {structuredAttachmentFiles.NM2.map((fileName, index) => (
                                          fileName.split(",").map((name, subIndex) => (
                                              <li key={`${index}-${subIndex}`} style={{ margin: "8px 0" }}>
                                                  <a
                                                      href="#!"
                                                      onClick={() => handleViewFile(name.trim())}
                                                      style={{
                                                          color: "blue",
                                                          textDecoration: "underline",
                                                          cursor: "pointer",
                                                      }}
                                                  >
                                                      {name.trim()}
                                                  </a>
                                              </li>
                                          ))
                                      ))}
                                  </ul>
                              </>
                          )}
                          {/* NM4: 계약서류 */}
                          {structuredAttachmentFiles.NM4.length > 0 && (
                              <>
                                  <h3>계약서류</h3>
                                  <ul style={{ listStyleType: "none", padding: 0 }}>
                                      {structuredAttachmentFiles.NM4.map((fileName, index) => (
                                          fileName.split(",").map((name, subIndex) => (
                                              <li key={`${index}-${subIndex}`} style={{ margin: "8px 0" }}>
                                                  <a
                                                      href="#!"
                                                      onClick={() => handleViewFile(name.trim())}
                                                      style={{
                                                          color: "blue",
                                                          textDecoration: "underline",
                                                          cursor: "pointer",
                                                      }}
                                                  >
                                                      {name.trim()}
                                                  </a>
                                              </li>
                                          ))
                                      ))}
                                  </ul>
                              </>
                          )}

                          {/* NM3: 기타서류 */}
                          {structuredAttachmentFiles.NM3.length > 0 && (
                              <>
                                  <h3>기타서류</h3>
                                  <ul style={{ listStyleType: "none", padding: 0 }}>
                                      {structuredAttachmentFiles.NM3.map((fileName, index) => (
                                          fileName.split(",").map((name, subIndex) => (
                                              <li key={`${index}-${subIndex}`} style={{ margin: "8px 0" }}>
                                                  <a
                                                      href="#!"
                                                      onClick={() => handleViewFile(name.trim())}
                                                      style={{
                                                          color: "blue",
                                                          textDecoration: "underline",
                                                          cursor: "pointer",
                                                      }}
                                                  >
                                                      {name.trim()}
                                                  </a>
                                              </li>
                                          ))
                                      ))}
                                  </ul>
                              </>
                          )}


                      </>
                  ) : (
                      <p>첨부파일이 없습니다.</p>
                  )}
                  <Button onClick={handleCloseModal} variant="contained" color="secondary">
                      닫기
                  </Button>
              </div>
          </Modal>



          <br></br>
          <br></br>
          <Card>
              <CardHeader/>
              <Divider/>
              <div className="ag-theme-alpine" style={{height: 520, width: "100%"}}>
                  <AgGridReact
                      ref={gridRef}
                      rowData={rowData}
                      columnDefs={columnDefs}
                      defaultColDef={{
                          resizable: true,
                          sortable: true,
                      }}
                      singleClickEdit={true}
                  />
              </div>
          </Card>
      </>
  );
};

export default ApplyMidSl;