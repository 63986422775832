import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Grid
} from '@material-ui/core';
import Page from '../../../components/Page';
import ApplyMidSl from "./ApplyMidSl";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ApplyResultView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="등록결과"
    >
      <Container maxWidth={false}>
      <Grid
          container
          spacing={3}
        >

          <Grid
            item
            lg={12}
            // sm={6}
            sm={12}
            xl={12}
            xs={12}
          >
             <ApplyMidSl />
          </Grid>
          </Grid>

       
   
      </Container>
    </Page>
  );
};

export default ApplyResultView;
